import React, { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, View, Text, Image } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import COLORS from '../../consts/colors';
import ItemInCart from '../components/ItemInCart';
import Loader from '../components/Loader';
import API from '../../api/server';

let maxWidth = 800;

const DetailsScreen = ({ navigation, route }) => {
  const [item, setItem] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  let updateCount = route.params.updateCount;

  useEffect(() => {
    setItem(route.params.food);
    setIsLoading(false);
  }, [route.params.food]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, marginHorizontal: 'auto', width: '100%' }}>
          <View style={style.header}>
            <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{item.title}</Text>
          </View>
          <ScrollView showsVerticalScrollIndicator={false}>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: 280,
              }}>
              <Image
                source={{ uri: item.src }}
                style={{ borderRadius: 30, height: 220, width: 220 }}
              />
            </View>
            <View style={style.details}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontSize: 25,
                    fontWeight: 'bold',
                    color: COLORS.white,
                  }}>
                  {item.title}
                  {item.weight != null && <Text> {item.weight} гр.</Text>}
                </Text>
                <Text
                  style={{
                    fontSize: 22,
                    fontWeight: 'bold',
                    color: COLORS.white,
                  }}>
                  {item.finalPrice}₽
                </Text>
              </View>
              <Text style={style.detailsText}>{item.description}</Text>
              <ItemInCart
                navigation={navigation}
                updateCount={updateCount}
                full={true}
                food={item}
              />
            </View>
          </ScrollView>
        </SafeAreaView>
      )}
    </>
  );
};

const style = StyleSheet.create({
  header: {
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 20,
  },
  details: {
    paddingHorizontal: 20,
    paddingTop: 40,
    paddingBottom: 60,
    backgroundColor: COLORS.primary,
    borderTopRightRadius: 40,
    borderTopLeftRadius: 40,
  },
  iconContainer: {
    backgroundColor: COLORS.white,
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
  },
  detailsText: {
    marginTop: 10,
    lineHeight: 22,
    fontSize: 16,
    color: COLORS.white,
  },
});

export default DetailsScreen;
