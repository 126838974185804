import React, {Component, useEffect, useState} from 'react';
import {SafeAreaView, StyleSheet, Text, View} from 'react-native';
import COLORS from '../../../consts/colors';
import {FlatList} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {PrimaryButton} from '../../components/Button';
import API from '../../../api/server';
import Overload from "../../components/overload";
import {Input} from 'react-native-elements';


let maxWidth = 800;

function CheckoutName({navigation}) {
  const [name, setName] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  const set = (value) => {
    if (value === ' ') {
      return;
    }

    setName(value);

    if (value.length > 1) {
      setConfirmed(true);
    } else {
      setConfirmed(false);
    }
  };

  const check = () => {
    if (name.length > 1) {
      setConfirmed(true);
      return true;
    } else {
      setConfirmed(false);
      return false;
    }
  };

  const next = () => {
    if (check()) {
      navigation.navigate('CheckoutPhone', {name: name});
    }
  };

  const auth = async () => {
    let account = await API.getAccount();

    if (
      account.number === '' ||
      account.number == null ||
      account.auth_token == null ||
      account.auth_token === ''
    ) {
      return;
    }

    const response = await API.verificationNeed({
      number: account.number,
      auth_token: account.auth_token,
    });

    const accountName = await API.getAccountName();

    if (response.response === false) {
      navigation.navigate('CheckoutDeliveryType', {
        name: accountName.name,
        number: account.number,
      });
    }
  };

  useEffect(() => {
    auth();
  }, []);

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto'}}>
      <Overload />
      <View style={style.header}>
        <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
      </View>
      <View style={{marginTop: 30}}>
        <Text style={{fontSize: 26, fontWeight: 'bold', textAlign: 'center'}}>
          Как вас зовут?
        </Text>
        <View style={style.header}>
          <View style={style.inputContainer}>
            <Icon name="people" size={28} />
            <Input
              onChangeText={(text) => {
                set(text);
              }}
              style={{flex: 1, fontSize: 18, width: '100%'}}
              placeholder="Имя"
            />
          </View>
        </View>

        <View
          style={{
            marginTop: 50,
            justifyContent: 'center',
            alignSelf: 'center',
            maxWidth: 200,
            width: '100%',
          }}>
          {confirmed && (
            <PrimaryButton title="Продолжить" onPress={() => next()} />
          )}
        </View>
      </View>
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 50,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CheckoutName;
