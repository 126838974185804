import React, { useEffect, useState } from 'react';
import {
    FlatList,
    ScrollView,
    TextInput,
    TouchableHighlight,
    TouchableOpacity,
} from 'react-native-gesture-handler';

import {
    Dimensions,
    Image,
    StyleSheet,
    Text,
    View,
} from 'react-native';


import COLORS from '../../consts/colors.js';
import API from '../../api/server.js';
import ItemInCart from './ItemInCart.js';


export default function SaleProduct({ navigation, food, updateCount, cardWidth, getFoodPrice }) {

    const [latestTime, setText] = useState("Акция");


    if (food == null) return null;

    const style = StyleSheet.create({
        card: {
            height: 220,
            width: cardWidth,
            marginHorizontal: 10,
            marginBottom: 20,
            marginTop: 50,
            borderRadius: 15,
            elevation: 13,
            backgroundColor: COLORS.light,
        },
        addToCartBtn: {
            height: 30,
            width: 30,
            borderRadius: 20,
            backgroundColor: COLORS.primary,
            justifyContent: 'center',
            alignItems: 'center',
        },
    });

    function getTimeString() {
        if (food.hit_expired == null) return setText("Акция");

        let seconds = 0;
        let minutes = 0;
        let hours = 0;

        let tmp = Number(food.hit_expired) - Date.now();

        if(tmp <= 0)
            return setText("Время акции истекло");

        tmp = Number(Number(tmp / 1000).toFixed(0));

        while (tmp - 3600 > 0) {
            hours++;
            tmp -= 3600;
        }

        while (tmp - 60 > 0) {
            minutes++;
            tmp -= 60;
        }

        seconds = tmp;

        if (hours < 10)
            hours = "0" + hours;

        if (minutes < 10)
            minutes = "0" + minutes;

        if (seconds < 10)
            seconds = "0" + seconds;

        setText(hours + ":" + minutes + ":" + seconds)
    }

    useEffect(() => {
        getTimeString();
        setInterval(() => {
            getTimeString();
        }, 1000)
    }, []);

    return (
        <TouchableHighlight
            underlayColor={COLORS.white}
            activeOpacity={0.9}
            onPress={() =>
                navigation.navigate('DetailsScreen', { food: food, updateCount })
            }>
            <View style={style.card}>
                <View style={{ alignItems: 'center', top: -40 }}>
                    <Image source={{ uri: food.src }} style={{ height: 120, width: 120 }} />
                </View>
                <View style={{ marginHorizontal: 20, marginTop: -20 }}>
                    <Text numberOfLines={1} style={{ fontSize: 18, fontWeight: 'bold' }}>
                        {food.title}
                    </Text>
                    <Text style={{ textAlign: "center", fontWeight: "bold" }}>{latestTime}</Text>
                </View>
                <View
                    style={{
                        marginTop: 10,
                        marginHorizontal: 20,
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                    <View style={{flexDirection: "column"}}>
                        <Text style={{ fontSize: 18, fontWeight: 'bold', color: "red" }}>
                            {food.price}₽
                        </Text>
                        <Text style={{ fontSize: 15, color: "black", textDecorationLine: "line-through" }}>{food.standard_price}₽</Text>
                    </View>
                    <ItemInCart navigation={navigation} full={false} food={food} />
                </View>
            </View>
        </TouchableHighlight>
    );
}