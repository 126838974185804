import React, { useEffect, useState } from 'react';
import {
  Text,
  StyleSheet,
  View,
  Image,
  TextInput,
  Dimensions,
  TouchableOpacity,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import COLORS from '../../consts/colors';
import { PrimaryButton } from '../components/Button';
import Icon from 'react-native-vector-icons/MaterialIcons';
import API from '../../api/server';
import { Button, Input, ListItem } from 'react-native-elements';
import Delivery from '../../api/delivery';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Loader from '../components/Loader';

let maxWidth = 800;

const SelectAddressScreen = ({ navigation }) => {
  const [address, setAddress] = useState('');
  const [query, setQuery] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isSearch, setIsSearch] = useState(false);

  const [isOther, setIsOther] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isFlat, setIsFlat] = useState(true);

  const [flat, setFlat] = useState('');
  const [floor, setFloor] = useState('');
  const [entrance, setEntrance] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSearch, setIsLoadingSearch] = useState(true);

  const [timeout, addTimeout] = useState(null);


  const updateAddress = async (text) => {
    setAddress(text);

    setIsSearch(true);

    if (text.length < 3) {
      return;
    }

    if(timeout != null) {
      console.log('clear')
      clearTimeout(timeout);
    }

    setIsLoadingSearch(true);
    addTimeout(setTimeout(async () => {
      const response = await API.GeoMapCoords(text);

      if (response.response === true) {
        setQuery(response.query);
      }

      setIsLoadingSearch(false);

    }, 1000))


  };

  const next = async () => {
    if (
      selectedAddress.title === null ||
      selectedAddress.latitude == null ||
      selectedAddress.longitude == null
    ) {
      alert('Не указан адрес');
      return;
    }

    if (isFlat === true && flat.length < 1) {
      alert('Не указана квартира');
      return;
    }

    if (isFlat === true) {
      try {
        let coords = await API.getCoords();
        coords.flat = flat;
        coords.floor = floor;
        coords.entrance = entrance;
        await AsyncStorage.setItem('coords', JSON.stringify(coords));
      } catch (err) {
        alert('Произошла ошибка сохранения адреса, попробуйте еще раз');
        return;
      }
    }

    let access = Delivery.inPolygon({
      lat: selectedAddress.latitude,
      lng: selectedAddress.longitude,
    });

    if (access.access === true) {
      navigation.navigate('Home');
    }
  };

  const selectAddress = async (data) => {
    let access = Delivery.inPolygon({ lat: data.latitude, lng: data.longitude });

    if (access.access === true) {
      const response = await API.pinNearestCenter(
        data.latitude,
        data.longitude,
      );

      if (response.response === true) {
        try {
          await AsyncStorage.setItem(
            'coords',
            JSON.stringify({
              center: response.center,
              latitude: response.coords.lat,
              longitude: response.coords.lng,
              custom: isCustom,
              other: isOther,
              isFlat: isFlat,
            }),
          );

          setSelectedAddress(data);
        } catch (err) {
          alert('Произошла ошибка установки адреса, попробуйте еще раз');
        }
      } else {
        alert('Доставка на этот адрес не осуществляется');
      }
    } else {
      alert('Доставка на этот адрес не осуществляется');
    }
  };

  const check = async () => {
    setIsLoading(true);
    let coords = await API.getCoords();

    if (coords == null) {
      setIsLoading(false);
      return;
    }

    if (coords.latitude == null || coords.longitude == null) {
      setIsLoading(false);
      return;
    }

    let access = Delivery.inPolygon({
      lat: coords.latitude,
      lng: coords.longitude,
    });

    if (access.access === true) {
      if (
        coords.flat != null ||
        coords.custom != null ||
        coords.other != null
      ) {
        navigation.navigate('Home');
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    navigation.addListener('focus', () => {
      check().then((r) => console.log('address checked'));
    });

    check().then((r) => console.log('address checked'));
  }, []);

  return (
    <View style={{ flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto' }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <View style={style.textContainer}>
            <View>
              <Text
                style={{
                  fontSize: 32,
                  marginTop: 50,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}>
                Адрес доставки
              </Text>
              <Text
                style={{
                  marginTop: 20,
                  fontSize: 18,
                  textAlign: 'center',
                  color: COLORS.grey,
                }}>
                Введите улицу и номер дома
              </Text>

              {selectedAddress != null && (
                <Text
                  style={{
                    fontWeight: 'bold',
                    fontSize: 16,
                    textAlign: 'center',
                  }}>
                  {selectedAddress.title}
                </Text>
              )}
              <TextInput
                value={address}
                onChangeText={(text) => updateAddress(text)}
                placeholder="Введите адрес"
                style={{
                  width: '100%',
                  minHeight: 50,
                  maxWidth: 500,
                  alignSelf: 'center',
                  backgroundColor: COLORS.light,
                  borderRadius: 20,
                  textAlign: 'center',
                }}
              />
            </View>

            <View>
              {query.length < 1 && isSearch === true && isLoadingSearch === false && (
                <Text
                  style={{
                    fontSize: 18,
                    marginTop: 20,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}>
                  Ничего не найдено
                </Text>
              )}

              {isLoadingSearch === true && isSearch === true && (
                <Text
                  style={{
                    fontSize: 18,
                    marginTop: 20,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}>
                  Загружается
                </Text>
              )}

              {query.map((q, index) => (
                <TouchableOpacity key={index} onPress={() => selectAddress(q)}>
                  <ListItem containerStyle={{ alignItems: 'center', flexDirection: 'row' }}>
                    <ListItem.Content style={{ alignItems: 'center', flexDirection: 'row' }} containerStyle={{ alignItems: 'center', flexDirection: 'row' }}>
                      <Button onPress={() => selectAddress(q)} buttonStyle={{ backgroundColor: COLORS.primary }} title="Выбрать" />
                      <Text style={{ padding: 5 }}>{q.title}</Text>
                    </ListItem.Content>
                  </ListItem>
                </TouchableOpacity>
              ))}
            </View>

            {isFlat && !isCustom && !isOther && (
              <View style={{ flexDirection: 'row', width: '100%' }}>
                <Input
                  maxLength={5}
                  numberOfLines={1}
                  containerStyle={{ width: '33%', maxWidth: '33%' }}
                  placeholder="Кв."
                  onChangeText={(text) => setFlat(text)}
                />
                <Input
                  maxLength={4}
                  containerStyle={{ width: '33%' }}
                  placeholder="Этаж"
                  onChangeText={(text) => setFloor(text)}
                />
                <Input
                  maxLength={4}
                  containerStyle={{ width: '33%' }}
                  placeholder="Подьезд"
                  onChangeText={(text) => setEntrance(text)}
                />
              </View>
            )}

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}>
              <Button
                onPress={() => {
                  setIsCustom(false);
                  setIsFlat(true);
                  setIsOther(false);
                }}
                containerStyle={{ padding: 10 }}
                buttonStyle={{
                  backgroundColor:
                    isFlat === true ? COLORS.primary : COLORS.blue,
                }}
                title="Квартира"
              />
              <Button
                onPress={() => {
                  setIsCustom(true);
                  setIsFlat(false);
                  setIsOther(false);
                }}
                containerStyle={{ padding: 10 }}
                buttonStyle={{
                  backgroundColor:
                    isCustom === true ? COLORS.primary : COLORS.blue,
                }}
                title="Частный дом"
              />
              <Button
                onPress={() => {
                  setIsCustom(false);
                  setIsFlat(false);
                  setIsOther(true);
                }}
                containerStyle={{ padding: 10 }}
                buttonStyle={{
                  backgroundColor:
                    isOther === true ? COLORS.primary : COLORS.blue,
                }}
                title="Другое"
              />
            </View>

            <View style={{ marginTop: 30 }}>
              <PrimaryButton onPress={() => next()} title="Продолжить" />
            </View>
          </View>
        </>
      )}
    </View>
  );
};

const style = StyleSheet.create({
  textContainer: {
    flex: 1,
    paddingHorizontal: 50,
    justifyContent: 'flex-start',
    height: '100%',
  },
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 50,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default SelectAddressScreen;
