import React, { Component, useEffect, useState } from 'react';
import API from '../../api/server';
import ItemInCart from '../components/ItemInCart';
import { FlatList, TouchableHighlight } from 'react-native-gesture-handler';
import COLORS from '../../consts/colors';
import {
  Dimensions,
  Image,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
const { width } = Dimensions.get('window');


let maxWidth = 1280;

if (width < maxWidth)
  maxWidth = width;

let cardWidth = 160;
let cardCount = 2;

if (maxWidth > 400) {
  cardCount = (maxWidth / (cardWidth)).toFixed(0);
  cardCount -= 1;
}

function CartOffersScreen({ navigation, route }) {
  let cost = route.params.cost;

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(true);

  const [offers, setOffers] = useState([]);

  const getOffers = async () => {
    setIsLoading(true);

    const response = await API.getOffers({ cost: Math.abs(500 - cost + 100) });

    if (response === false) {
      setIsError(true);
      setIsLoading(true);
      return;
    }

    if (response.response === true) {
      setIsError(false);
      if (response.data.length > 0) {
        setOffers(response.data);
      }
    } else {
      setIsError(false);
    }

    setIsLoading(false);
  };

  const getFoodPrice = (food) => {
    let price = food.finalPrice;

    if (food.count < 1) {
      return price;
    }

    if (food.components != null) {
      if (food.components.length > 0) {
        food.components.map((c) => {
          if (c.default) {
            price += Number(c.price);
          }
        });
      }
    }

    return price;
  };

  const updateCount = ({ id, count }) => {
    let tmp = [];
    let items = offers;

    items.map((f) => {
      if (f.id === id) {
        f.count = count;
      }

      tmp.push(f);
    });

    setOffers(tmp);
  };

  useEffect(() => {
    getOffers();
  }, [cost]);

  const Card = ({ food }) => {
    return (
      <TouchableHighlight
        underlayColor={COLORS.white}
        activeOpacity={0.9}
        onPress={() =>
          navigation.navigate('DetailsScreen', { food: food, updateCount })
        }>
        <View style={style.card}>
          <View style={{ alignItems: 'center', top: -40 }}>
            <Image source={{ uri: food.src }} style={{ height: 120, width: 120 }} />
          </View>
          <View style={{ marginHorizontal: 20 }}>
            <Text numberOfLines={1} style={{ fontSize: 18, fontWeight: 'bold' }}>
              {food.title}
            </Text>
            <Text
              numberOfLines={1}
              style={{ maxHeight: 20, fontSize: 14, color: COLORS.grey, marginTop: 2 }}>
              {food.description}
            </Text>
          </View>
          <View
            style={{
              marginTop: 10,
              marginHorizontal: 20,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Text style={{ fontSize: 18, fontWeight: 'bold' }}>
              {getFoodPrice(food)}₽
            </Text>
            <ItemInCart navigation={navigation} full={false} food={food} />
          </View>
        </View>
      </TouchableHighlight>
    );
  };

  return (
    <SafeAreaView style={{ backgroundColor: COLORS.white, flex: 1, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto' }}>
      <View style={style.header}>
        <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Предложения</Text>
      </View>
      <View style={{ marginTop: 50 }}>
        <View style={{ height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', marginHorizontal: 'auto', marginBottom: '20%' }}>
          <FlatList
            showsVerticalScrollIndicator={false}
            numColumns={cardCount}
            data={offers}
            renderItem={({ item }) => <Card food={item} />}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 50,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    height: 220,
    width: cardWidth,
    marginHorizontal: 10,
    marginBottom: 20,
    marginTop: 50,
    borderRadius: 15,
    elevation: 13,
    backgroundColor: COLORS.white,
  },
  addToCartBtn: {
    height: 30,
    width: 30,
    borderRadius: 20,
    backgroundColor: COLORS.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CartOffersScreen;
