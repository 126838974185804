import React, { useEffect, useState } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  Image,
  TextInput,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import COLORS from '../../consts/colors';
import { PrimaryButton } from '../components/Button';
import API from '../../api/server';
import Loader from '../components/Loader';
import { ListItem } from 'react-native-elements';
import { MaskedTextInput } from 'react-native-mask-text';
import AsyncStorage from '@react-native-async-storage/async-storage';

let maxWidth = 800;

const AccountScreen = ({ navigation }) => {
  const [name, setName] = useState('');
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [number, setNumber] = useState('');
  const [isConfirmed, setConfirmed] = useState(false);
  const [isCodeSend, setIsCodeSend] = useState(false);
  const [code, setCode] = useState('');

  const updateAuthNumber = (raw) => {
    setNumber(raw);

    if (raw.length === 11) {
      setConfirmed(true);
    } else {
      setConfirmed(false);
    }
  };

  const AuthSendCode = async () => {
    const response = await API.sendCode({ name, number });

    if (response.response !== true) {
      alert('Превышен лимит отправки звонков');
      return;
    } else {
      setIsCodeSend(true);
    }
  };

  const getAccount = async () => {
    setIsLoading(true);
    const response = await API.getAccountName();

    if (response.response === true) {
      setName(response.name);
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }

    setIsLoading(false);
  };

  const confirmAuthCode = async () => {
    if (code.length >= 4) {
      const response = await API.confirmCode({ number, code });

      if (response.response === true) {
        try {
          await AsyncStorage.setItem(
            'account',
            JSON.stringify({
              number: response.number,
              auth_token: response.auth_token,
            }),
          );

          getAccount();
        } catch (err) {
          alert('Произошла ошибка сохранения данных, повторите попытку');
        }
      } else {
        alert('Неверный код подтверждения');
      }
    }
  };

  useEffect(() => {
    navigation.addListener('focus', () => {
      getAccount();
    });

    getAccount();
  }, []);

  return (
    <SafeAreaView style={{ backgroundColor: COLORS.white, flex: 1, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto' }}>
      <View style={style.header}>
        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Аккаунт</Text>
      </View>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isAuth ? (
            <View>
              <View style={{ ...style.header, justifyContent: 'center' }}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 24,
                    fontWeight: 'bold',
                  }}>
                  Здравствуйте, {name}
                </Text>
              </View>

              <ListItem
                containerStyle={{ maxWidth: 400, width: '100%', alignSelf: 'center' }}
                onPress={() => navigation.navigate('MyOrdersScreen', { number })}
                bottomDivider>
                <ListItem.Content style={{ alignItems: 'center' }}>
                  <ListItem.Title style={{ fontWeight: 'bold', fontSize: 18 }}>
                    Заказы
                  </ListItem.Title>
                </ListItem.Content>
                <ListItem.Chevron />
              </ListItem>
            </View>
          ) : (
            <View style={{ alignItems: 'center', flex: 1 }}>
              {!isCodeSend && (
                <>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 24,
                    }}>
                    Введите ваш номер телефона
                  </Text>

                  <View style={style.inputContainer}>
                    <Icon name="phone" size={28} />
                    <MaskedTextInput
                      value={number}
                      mask="+7 (999) 999-9999"
                      onChangeText={(text, rawText) => {
                        updateAuthNumber(rawText);
                      }}
                      placeholder="+7 ("
                      style={{ width: '100%' }}
                    />
                  </View>

                  <View style={{ width: '100%', maxWidth: 225, marginTop: 30 }}>
                    {isConfirmed && (
                      <PrimaryButton
                        title="Продолжить"
                        onPress={() => AuthSendCode()}
                      />
                    )}
                  </View>
                </>
              )}

              {isCodeSend && (
                <>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    Введите последние 4 цифры номера позвонившего вам робота
                  </Text>
                  <View style={style.inputContainer}>
                    <Icon name="dashboard" size={28} />
                    <MaskedTextInput
                      value={number}
                      mask="9999"
                      onChangeText={(text, rawText) => {
                        setCode(rawText);
                      }}
                      placeholder="Код"
                      style={{ width: '100%' }}
                    />
                  </View>

                  {isConfirmed && (
                    <View style={{ width: '100%', maxWidth: 225, marginTop: 30 }}>
                      <PrimaryButton
                        title="Продолжить"
                        onPress={() => confirmAuthCode()}
                      />
                    </View>
                  )}
                </>
              )}
            </View>
          )}
        </>
      )}
    </SafeAreaView>
  );
};
const style = StyleSheet.create({
  header: {
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 20,
  },
  cartCard: {
    height: 100,
    elevation: 15,
    borderRadius: 10,
    backgroundColor: COLORS.white,
    marginVertical: 10,
    marginHorizontal: 20,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionBtn: {
    width: 80,
    height: 30,
    backgroundColor: COLORS.primary,
    borderRadius: 30,
    paddingHorizontal: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
  },
  inputContainer: {
    height: 50,
    maxWidth: 275,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
});

export default AccountScreen;
