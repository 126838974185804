import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import DetailsScreen from './src/views/screens/DetailsScreen';
import BottomNavigator from './src/views/navigation/BottomNavigator';
import OnBoardScreen from './src/views/screens/OnBoardScreen';
import JobScreen from './src/views/screens/JobScreen';
import SearchScreen from './src/views/screens/SearchScreen';
import AddComponentsScreen from './src/views/screens/AddComponentsScreen';
import CartOffersScreen from './src/views/screens/CartOffersScreen';
import CheckoutName from './src/views/screens/checkout/CheckoutName';
import CheckoutPhone from './src/views/screens/checkout/CheckoutPhone';
import CheckoutPhoneConfirm from './src/views/screens/checkout/CheckoutConfirmPhone';
import CheckoutAddress from './src/views/screens/checkout/CheckoutAddress';
import CheckoutChangeAddress from './src/views/screens/checkout/CheckoutChangeAddress';
import CheckoutDescription from './src/views/screens/checkout/CheckoutDescription';
import CheckoutBeforeOrder from './src/views/screens/checkout/CheckoutBeforeOrder';
import CheckoutFinish from './src/views/screens/checkout/CheckoutFinish';
import CheckoutDeliveryType from './src/views/screens/checkout/CheckoutDeliveryType';
import CheckoutSelectCenter from './src/views/screens/checkout/CheckoutSelectCenter';
import SelectAddressScreen from './src/views/screens/SelectAddressScreen';
import AccountScreen from './src/views/screens/AccountScreen';
import MyOrdersScreen from './src/views/screens/account/MyOrdersScreen';
import SuccessOrderScreen from './src/views/screens/checkout/SuccessOrderScreen';
import CartScreen from './src/views/screens/CartScreen';
import OrderInfoScreen from './src/views/screens/OrderInfoScreen';

const Stack = createStackNavigator();

class RIKIMARU {

  constructor() {
    this.activeOrder = {};
  }

}

export default function App() {
  window.app = new RIKIMARU();

  return (
    <NavigationContainer>
      <Stack.Navigator screenOptions={{ headerShown: false, title: "RIKIMARU - Доставка роллов и пицц по г. Зеленодольск" }}>
        <Stack.Screen options={{ title: 'Главная | RIKIMARU' }} name="BoardScreen" component={OnBoardScreen} />
        <Stack.Screen options={{ title: 'Главная | RIKIMARU' }} name="JobScreen" component={JobScreen} />
        <Stack.Screen options={{ title: 'Главная | RIKIMARU' }} name="Home" component={BottomNavigator} />
        <Stack.Screen options={{ title: 'Информация | RIKIMARU' }} name="DetailsScreen" component={DetailsScreen} />
        <Stack.Screen options={{ title: 'Поиск | RIKIMARU' }} name="SearchScreen" component={SearchScreen} />
        <Stack.Screen options={{ title: 'Аккаунт | RIKIMARU' }} name="AccountScreen" component={AccountScreen} />

        <Stack.Screen
          options={{ title: 'Аккаунт | RIKIMARU' }}
          name="AddComponentsScreen"
          component={AddComponentsScreen}
        />
        <Stack.Screen
          options={{ title: 'Предложения | RIKIMARU' }}
          name="CartOffersScreen" component={CartOffersScreen} />
        <Stack.Screen options={{ title: 'Оформление заказа | RIKIMARU' }} name="CheckoutNameScreen" component={CheckoutName} />
        <Stack.Screen options={{ title: 'Оформление заказа | RIKIMARU' }} name="CheckoutPhone" component={CheckoutPhone} />
        <Stack.Screen
          options={{ title: 'Оформление заказа | RIKIMARU' }}
          name="CheckoutPhoneConfirm"
          component={CheckoutPhoneConfirm}
        />
        <Stack.Screen options={{ title: 'Оформление заказа | RIKIMARU' }} name="CheckoutAddress" component={CheckoutAddress} />
        <Stack.Screen
          options={{ title: 'Оформление заказа | RIKIMARU' }}
          name="CheckoutChangeAddress"
          component={CheckoutChangeAddress}
        />
        <Stack.Screen
          options={{ title: 'Оформление заказа | RIKIMARU' }}
          name="CheckoutDescription"
          component={CheckoutDescription}
        />

        <Stack.Screen
          options={{ title: 'Оформление заказа | RIKIMARU' }}
          name="CheckoutBeforeOrder"
          component={CheckoutBeforeOrder}
        />

        <Stack.Screen options={{ title: 'Оформление заказа | RIKIMARU' }} name="CheckoutFinish" component={CheckoutFinish} />

        <Stack.Screen
          options={{ title: 'Оформление заказа | RIKIMARU' }}
          name="CheckoutDeliveryType"
          component={CheckoutDeliveryType}
        />

        <Stack.Screen
          options={{ title: 'Оформление заказа | RIKIMARU' }}
          name="CheckoutSelectCenter"
          component={CheckoutSelectCenter}
        />

        <Stack.Screen
          options={{ title: 'Оформление заказа | RIKIMARU' }}
          name="SelectAddressScreen"
          component={SelectAddressScreen}
        />

        <Stack.Screen
          options={{ title: 'Оформление заказа | RIKIMARU' }}
          name="SuccessOrderScreen"
          component={SuccessOrderScreen}
        />

        <Stack.Screen
          options={{ title: 'Информация о заказе | RIKIMARU' }}
          name="activeOrder"
          component={OrderInfoScreen}
        />

        <Stack.Screen options={{ title: 'Мои заказы | RIKIMARU' }} name="MyOrdersScreen" component={MyOrdersScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
