import React, { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, View, Text, Image } from 'react-native';
import { FlatList, ScrollView } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import COLORS from '../../consts/colors';
import { PrimaryButton } from '../components/Button';
import API from '../../api/server';
import CartOffers from '../components/CartOffers';
import Loader from '../components/Loader';

let maxWidth = 800;

const CartScreen = ({ navigation }) => {
  const [cost, setCost] = useState(0);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryPrice, setDeliveryPrice] = useState(90);

  const getCart = async () => {
    setIsLoading(true);
    const response = await API.getCart();

    if (response !== false) {
      if (response != null) {
        if (response.length > 0) {
          setItems(response);
        }
      }
    }

    setIsLoading(false);
  };

  const getComponentsName = ({ uuid }) => {
    let response = '';
    items.map((item) => {
      if (item.uuid === uuid) {
        let title = '';
        item.components.map((comp, index) => {
          if (item.components.length === index + 1) {
            title += comp.name;
          } else {
            title += comp.name + ', ';
          }
        });

        response = title;
      }
    });

    return response;
  };

  const RemoveItem = async (uuid) => {
    const response = await API.removeCartItem(uuid, 1);

    if (response !== false) {
      let tmp = [];
      let tmpItems = items;

      tmpItems.map((item) => {
        if (item.uuid === uuid) {
          if (item.count - 1 > 0) {
            item.count = Number(item.count) - 1;
            tmp.push(item);
          }
        } else {
          tmp.push(item);
        }
      });

      setItems(tmp);
    }
  };

  const AddCart = async ({ uuid, id, components }) => {
    const response = await API.addCartItem(id, components, uuid);

    if (response !== false) {
      let tmp = [];
      let tmpItems = items;

      tmpItems.map((item) => {
        if (item.uuid === uuid) {
          item.count = Number(item.count) + 1;
        }
        tmp.push(item);
      });

      setItems(tmp);
    }
  };

  const getFinalPrice = () => {
    let price = 0;
    let cart_price = 0;

    items.map((item) => {
      price += Number(Number(item.finalPrice) * Number(item.count));
    });

    cart_price = price;

    if (deliveryPrice === 90 && price < 500) {
      price += deliveryPrice;
    }

    if (deliveryPrice !== 90) {
      price += deliveryPrice;
    }

    return { price, cart_price };
  };

  useEffect(() => {
    navigation.addListener('focus', () => {
      getCart();
    });

    getCart();
  }, []);

  const CartCard = ({ item }) => {
    return (
      <View style={style.cartCard}>
        <Image
          source={{ uri: item.src }}
          style={{ borderRadius: 20, height: 80, width: 80 }}
        />
        <View
          style={{
            height: 100,
            marginLeft: 10,
            paddingVertical: 20,
            flex: 1,
          }}>
          <Text style={{ fontWeight: 'bold', fontSize: 16 }}>{item.title}</Text>
          <Text style={{ fontSize: 13, color: COLORS.grey }}>
            {getComponentsName({ uuid: item.uuid })}
          </Text>
          <Text style={{ fontSize: 17, fontWeight: 'bold' }}>
            {item.finalPrice * item.count}₽
          </Text>
        </View>
        <View style={{ marginRight: 20, alignItems: 'center' }}>
          <Text style={{ fontWeight: 'bold', fontSize: 18 }}>
            {item.count} шт
          </Text>
          <View style={style.actionBtn}>
            <Icon
              name="remove"
              onPress={() => RemoveItem(item.uuid)}
              size={25}
              color={COLORS.white}
            />
            <Icon
              onPress={() =>
                AddCart({
                  uuid: item.uuid,
                  id: item.id,
                  components: item.components,
                })
              }
              name="add"
              size={25}
              color={COLORS.white}
            />
          </View>
        </View>
      </View>
    );
  };

  return (
    <SafeAreaView style={{ backgroundColor: COLORS.white, flex: 1, maxWidth: maxWidth, marginHorizontal: 'auto', width: '100%' }}>
      <View style={style.header}>
        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Корзина</Text>
      </View>
      {isLoading ? (
        <>
          <Loader />
          <Text style={{ fontWeight: "bold", fontSize: 24 }}>Обновления наличия товаров...</Text>
        </>
      ) : (
        <ScrollView>
          <FlatList
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ paddingBottom: 80 }}
            data={items}
            renderItem={({ item }) => <CartCard item={item} />}
            ListFooterComponentStyle={{ paddingHorizontal: 20, marginTop: 20 }}
            ListFooterComponent={() => (
              <View>
                <CartOffers
                  navigation={navigation}
                  deliveryPrice={deliveryPrice}
                  cost={getFinalPrice()['cart_price']}
                />
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginVertical: 15,
                  }}>
                  <Text style={{ fontSize: 18, fontWeight: 'bold' }}>
                    Сумма заказа
                  </Text>
                  <Text style={{ fontSize: 18, fontWeight: 'bold' }}>
                    {getFinalPrice()['cart_price']} ₽
                  </Text>
                </View>
                <View style={{ marginHorizontal: 30 }}>
                  {getFinalPrice()['cart_price'] < 200 && (
                    <PrimaryButton
                      title="Мин. 200 рублей"
                    />
                  )}

                  {getFinalPrice()['cart_price'] >= 200 && (
                    <PrimaryButton
                      onPress={() => navigation.navigate('CheckoutNameScreen')}
                      title="Оформить заказ"
                    />
                  )}

                </View>
              </View>
            )}
          />
        </ScrollView>
      )}
    </SafeAreaView>
  );
};
const style = StyleSheet.create({
  header: {
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 20,
  },
  cartCard: {
    height: 100,
    elevation: 15,
    borderRadius: 10,
    backgroundColor: COLORS.white,
    marginVertical: 10,
    marginHorizontal: 20,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionBtn: {
    width: 80,
    height: 30,
    backgroundColor: COLORS.primary,
    borderRadius: 30,
    paddingHorizontal: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
  },
});

export default CartScreen;
