import React, { useEffect } from 'react';
import { Text, StyleSheet, View, Image, Linking, Platform } from 'react-native';
import API from '../../api/server';
import COLORS from '../../consts/colors';
import { PrimaryButton } from '../components/Button';

let maxWidth = 800;

const OnBoardScreen = ({ navigation }) => {

  useEffect(() => {
    API.updateCookies()
  }, [])

  return (
    <View style={{ flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto' }}>
      <View style={style.textContainer}>
        <View style={{ height: 100 }}>
          <Image
            style={{
              width: '100%',
              height: '100%',
              resizeMode: 'contain',
            }}
            source={{ uri: 'https://friends.sandello.ru/static/logo/onboardImage.png' }}
          />
        </View>
        <Text style={{ fontSize: 32, fontWeight: 'bold', textAlign: 'center' }}>
          RIKIMARU
        </Text>
        <Text
          style={{
            marginTop: 20,
            marginBottom: 20,
            fontSize: 18,
            textAlign: 'center',
            color: COLORS.grey,
          }}>
          Доставка роллов и пицц по г. Зеленодольск с 10:00 до 22:30
        </Text>

        {/*<PrimaryButton
          onPress={() => navigation.navigate('JobScreen')}
          title="Вакансии"
        />*/}

        <PrimaryButton
          onPress={() => navigation.navigate('SelectAddressScreen')}
          title="Заказать"
        />

<Text
          style={{
            marginTop: 10,
            fontSize: 22,
            textAlign: 'center',
            color: 'black',
            fontWeight: 'bold'
          }}>
          Наши точки:
        </Text>

        <View style={{ flexDirection: 'row', display: "flex", justifyContent: 'center', flexWrap: 'wrap', marginBottom: '5%' }}>
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Text
              style={{
                minWidth: 150,
                marginTop: 20,
                fontSize: 18,
                textAlign: 'center',
                color: COLORS.primary,
                margin: '5%'
              }}>
              ул. Заикина 16 (Заикина)
            </Text>
            <Text onPress={() => Linking.openURL(`tel:+79172802840`)}>+79172403100</Text>
            <Text onPress={() => Linking.openURL(`tel:88437166929`)}>88437143435</Text>
          </View>

          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Text
              style={{
                minWidth: 150,
                marginTop: 20,
                fontSize: 18,
                textAlign: 'center',
                color: COLORS.primary,
                margin: '5%'
              }}>
              ул. Комарова 43 (Мирный)
            </Text>
            <Text style={{ color: 'black' }} onPress={() => Linking.openURL(`tel:+79172802840`)}>+79172802840</Text>
            <Text style={{ color: 'black' }} onPress={() => Linking.openURL(`tel:88437166929`)}>88437166929</Text>
          </View>
        </View>

      {Platform.OS === "web" && (
          <>
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontSize: 18,
                textAlign: 'center',
                color: COLORS.grey,
              }}>
              Устанавливайте наше мобильное приложение для Android
            </Text>
            <PrimaryButton
              onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.rikimaru&hl=ru&gl=RU")}
              title="Скачать"
            />
          </>
        )}
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  textContainer: {
    flex: 1,
    paddingHorizontal: 50,
    paddingBottom: 40,
  },
  currentIndicator: {
    height: 12,
    width: 30,
    borderRadius: 10,
    backgroundColor: COLORS.primary,
    marginHorizontal: 5,
  },
  indicator: {
    height: 12,
    width: 12,
    borderRadius: 6,
    backgroundColor: COLORS.grey,
    marginHorizontal: 5,
  },
});

export default OnBoardScreen;
