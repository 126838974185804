import React, {Component, useEffect, useState} from 'react';
import {Button, Text, View} from 'react-native';
import {Dialog} from 'react-native-elements';
import API from '../../api/server';

function Overload() {
  const [isVisible, setIsVisible] = useState(false);
  const [isOffline, setisOffline] = useState(false);

  const [name, setName] = useState('');
  const [time, setTime] = useState('');

  async function getStatus() {
    const response = await API.getCenterStatus();

    if (response.response) {
      if(response.disable === 1) {
        setisOffline(true);
      }

      if (response.overload) {
        setName(response.title);
        setTime(((response.time)/60).toFixed(1));
        setIsVisible(true);
      }
    }
  }

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <View style={{maxWidth: 300}}>
      <Dialog overlayStyle={{maxWidth: 300}} isVisible={isVisible} onBackdropPress={setIsVisible}>
        <Dialog.Title
          titleStyle={{textAlign: 'center'}}
          title="Перегруз на кухне"
        />
        <Text style={{textAlign: 'center'}}>
          На точке {name} перегруз. Доставляем от двух часов и более.
        </Text>
        <Button
          containerStyle={{marginTop: '5%'}}
          onPress={() => {
            setIsVisible(!isVisible);
          }}
          title="Хорошо"
        />
      </Dialog>

      <Dialog overlayStyle={{maxWidth: 300}} isVisible={isOffline} onBackdropPress={setisOffline}>
        <Dialog.Title
          titleStyle={{textAlign: 'center'}}
          title="Прием заказов отключен"
        />
        <Text style={{textAlign: 'center'}}>
          Вы можете просматривать каталог товаров, но оформить заказ на сайте вы сможете после его включения
        </Text>
        <Button
          containerStyle={{marginTop: '5%'}}
          onPress={() => {
            setisOffline(!isOffline);
          }}
          title="Хорошо"
        />
      </Dialog>
    </View>
  );
}

export default Overload;
