import React, { useEffect, useState } from 'react';
import {
    FlatList,
    ScrollView,
    TextInput,
    TouchableHighlight,
    TouchableOpacity,
} from 'react-native-gesture-handler';

import {
    Dimensions,
    Image,
    StyleSheet,
    Text,
    View,
} from 'react-native';


import COLORS from '../../consts/colors.js';
import API from '../../api/server.js';

const { width } = Dimensions.get('window');

let maxWidth = 1280;

if (width < maxWidth)
    maxWidth = width;

import pizza from '../../assets/catergories/pizza.png';
import rolls from '../../assets/catergories/rolls.png';
import roll from '../../assets/catergories/roll.png';
import snacks from '../../assets/catergories/snacks.png';
import juice from '../../assets/catergories/juice.png';
import salad from '../../assets/catergories/salad.png';
import sauce from '../../assets/catergories/sauce.png';
import kit from '../../assets/catergories/kit.png';


const categoryImg = (name) => {
    switch (name) {
        case 'pizza':
            return pizza;
        case 'roll':
            return roll;
        case 'rolls':
            return rolls;
        case 'snacks':
            return snacks;
        case 'juice':
            return juice;
        case 'salad':
            return salad;
        case 'sauce':
            return sauce;
        case 'kit':
            return kit;
    }
};

export default function UnderListCategories({ navigation, categories, updateCategory, isUnder, categoryId }) {
    const [selectedUnderCategoryIndex, setUnderCategoryIndex] = useState(-1);
    
    useEffect(() => {
        updateCategory(selectedUnderCategoryIndex);
    }, [selectedUnderCategoryIndex]);

    return (
        <View
            style={style.categoriesListContainer}>
            <FlatList
                showsHorizontalScrollIndicator={false}
                style={{ maxWidth: maxWidth }}
                horizontal
                data={categories}
                renderItem={({ item }) =>
                    <>
                        {item.under != null && item.under === categoryId && (
                            <TouchableOpacity
                                activeOpacity={0.8}
                                onPress={() => {
                                    setUnderCategoryIndex(item.id);
                                }}>
                                <View
                                    style={{
                                        padding: 10,
                                        margin: 10,
                                        minWidth: 32,
                                        minHeight: 32,
                                        backgroundColor:
                                            selectedUnderCategoryIndex === item.id
                                                ? COLORS.primary
                                                : COLORS.secondary,
                                        ...style.categoryBtn,
                                    }}>
                                    <View style={style.categoryBtnImgCon}>
                                        <Image
                                            source={categoryImg(item.src)}
                                            style={{
                                                height: 35,
                                                width: 35,
                                                borderRadius: 30,
                                                resizeMode: 'cover',
                                            }}
                                        />
                                    </View>
                                    <Text
                                        style={{
                                            fontSize: 15,
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            justifyContent: "center",
                                            display: "flex",
                                            marginTop: 5,
                                            color:
                                                selectedUnderCategoryIndex === item.id
                                                    ? COLORS.white
                                                    : COLORS.primary,
                                        }}>
                                        {item.title}
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        )}
                    </>
                }
            />
        </View>
    );

}


const style = StyleSheet.create({
    categoriesListContainer: {
        alignItems: 'center',
        marginHorizontal: 5,
        paddingBottom: 30,
    },
    categoryBtn: {
        height: 45,
        width: 120,
        marginRight: 7,
        borderRadius: 30,
        alignItems: 'center',
        paddingHorizontal: 5,
        flexDirection: 'row',
    },
    underCategoryBtn: {
        height: 85,
        width: 120,
        marginRight: 7,
        borderRadius: 30,
        alignItems: 'center',
        paddingHorizontal: 5,
        flexDirection: 'row',
    },
    categoryBtnImgCon: {
        height: 35,
        width: 35,
        borderRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    }
});