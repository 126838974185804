import React, {Component} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {CheckBox} from 'react-native-elements';
import COLORS from '../../consts/colors';

function Components({navigation, groups, item, selectComponent, update}) {
  return (
    <>
      {update ? (
        <>
          {groups.map((group, index) => (
            <View key={index}>
              <Text style={style.titleGroupText}>{group.title}</Text>
              {item.components.map((comp, index) => (
                <View key={index}>
                  {comp.group === group.id && (
                    <>
                      <CheckBox
                        center
                        title={comp.name + ' - ' + comp.price + '₽'}
                        checkedIcon="dot-circle-o"
                        uncheckedIcon="circle-o"
                        checked={comp.default}
                        onPress={() => {
                          selectComponent({group: group.id, id: comp.id});
                        }}
                      />
                    </>
                  )}
                </View>
              ))}
            </View>
          ))}
        </>
      ) : (
        <>
          {groups.map((group, index) => (
            <View key={index}>
              <Text style={style.titleGroupText}>{group.title}</Text>
              {item.components.map((comp, index) => (
                <View key={index}>
                  {comp.group === group.id && (
                    <>
                      <CheckBox
                        center
                        title={comp.name + ' - ' + comp.price + '₽'}
                        checkedIcon="dot-circle-o"
                        uncheckedIcon="circle-o"
                        checked={comp.default}
                        onPress={() => {
                          selectComponent({group: group.id, id: comp.id});
                        }}
                      />
                    </>
                  )}
                </View>
              ))}
            </View>
          ))}
        </>
      )}
    </>
  );
}

const style = StyleSheet.create({
  header: {
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 20,
  },
  details: {
    paddingHorizontal: 20,
    paddingTop: 40,
    paddingBottom: 60,
    backgroundColor: COLORS.primary,
    borderTopRightRadius: 40,
    borderTopLeftRadius: 40,
  },
  iconContainer: {
    backgroundColor: COLORS.white,
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
  },
  detailsText: {
    marginTop: 10,
    lineHeight: 22,
    fontSize: 16,
    color: COLORS.white,
  },
  titleGroupText: {
    color: COLORS.white,
    paddingVertical: 15,
    fontSize: 20,
    fontWeight: 'bold',
    marginLeft: '5%',
  },
});

export default Components;
