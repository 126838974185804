import React, { Component, useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, Text, View } from 'react-native';
import COLORS from '../../../consts/colors';
import { FlatList, TextInput } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { PrimaryButton } from '../../components/Button';
import { Button } from 'react-native-elements';
import API from '../../../api/server';
import Loader from '../../components/Loader';
import Delivery from '../../../api/delivery';
import AsyncStorage from '@react-native-async-storage/async-storage';

let maxWidth = 800;

function CheckoutAddress({ navigation, route }) {
  let name = route.params.name;
  let number = route.params.number;
  let deliveryType = route.params.deliveryType;
  const [confirmed, setConfirmed] = useState(false);
  const [address, setAddress] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [addressData, setAddressData] = useState({ flat: -1, floor: -1, entrance: -1 });

  useEffect(() => {
    if (route.params.address != null) {
      setAddress(route.params.address);
      check(route.params.address);
    } else {
      loadAddress();
    }
  }, [route.params.address]);

  const loadAddress = async () => {
    let data = await API.getCoords();

    if (data == null) {
      navigation.navigate('CheckoutChangeAddress', {
        name,
        number,
        address,
        deliveryType,
      });
    }

    if (
      data == null ||
      data.longitude == null ||
      data.latitude == null ||
      data.longitude === '' ||
      data.latitude === ''
    ) {
      await AsyncStorage.removeItem('coords');
      navigation.navigate('SelectAddressScreen');
    }

    if(data.flat != null && data.floor != null && data.entrance != null)
      setAddressData({ flat: data.flat, floor: data.floor, entrance: data.entrance });
      
    const response = await API.GeoMap(data.latitude, data.longitude);

    if (response.response === true) {
      setIsLoading(true);
      setAddress({
        title: response.address,
        latitude: data.latitude,
        longitude: data.longitude,
      });
      setConfirmed(true);
    }

    setIsLoading(false);
  };

  const changeAddress = async () => {
    await AsyncStorage.removeItem('coords');
    navigation.navigate('SelectAddressScreen');
  };

  const check = async (data) => {
    if (data == null) {
      return;
    }

    setIsLoading(true);
    if (data.length > 10) {
      const response = await API.GeoMapCoords(data);

      if (response.response === true) {
        let access = Delivery.inPolygon({
          lat: response.query[0].latitude,
          lng: response.query[0].longitude,
        });

        if (access.access !== true) {
          changeAddress();
        } else {
          setConfirmed(true);
        }
      }
    }
    setIsLoading(false);
  };

  const next = () => {
    navigation.navigate('CheckoutBeforeOrder', {
      name: name,
      number,
      address,
      deliveryType,
    });
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto' }}>
      <View style={style.header}>
        <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
      </View>
      {isLoading ? (
        <Loader />
      ) : (
        <View style={{ marginTop: 30 }}>
          <Text style={{ fontSize: 26, fontWeight: 'bold', textAlign: 'center' }}>
            Адрес доставки
          </Text>
          <View style={style.header}>
            <View style={style.inputContainer}>
              <Icon name="place" size={18} />
              <Text
                numberOfLines={1}
                style={{ fontSize: 18, fontWeight: 'bold' }}>
                {address.title}
              </Text>
            </View>
            <Button
              onPress={() => changeAddress()}
              buttonStyle={{ backgroundColor: COLORS.primary }}
              containerStyle={{ padding: 5 }}
              title="Изменить"
            />
          </View>

          {
            addressData.flat != -1 && addressData.flat.length > 0 && (
              <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: 17}}>
                Квартира: {addressData.flat}
              </Text>
            )
          }

          {
            addressData.floor != -1 && addressData.floor.length > 0 && (
              <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: 17}}>
                Этаж: {addressData.floor}
              </Text>
            )
          }

          {
            addressData.entrance != -1 && addressData.entrance.length > 0 && (
              <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: 17}}>
                Подьезд: {addressData.entrance}
              </Text>
            )
          }

          <View
            style={{
              marginTop: 50,
              justifyContent: 'center',
              alignSelf: 'center',
              maxWidth: 200,
              width: '100%',
            }}>
            {confirmed && (
              <PrimaryButton title="Продолжить" onPress={() => next()} />
            )}
          </View>
        </View>
      )}
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 50,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CheckoutAddress;
