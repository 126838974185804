import React, { Component, useEffect, useState } from 'react';
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from 'react-native';
import COLORS from '../../../consts/colors';
import { FlatList, TextInput } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { PrimaryButton } from '../../components/Button';
import API from '../../../api/server';
import Loader from '../../components/Loader';
import { Button, Dialog } from 'react-native-elements';
import { Picker } from '@react-native-picker/picker';

let maxWidth = 800;

function CheckoutFinish({ navigation, route }) {
  let deliveryType = route.params.deliveryType;
  let center = route.params.center;
  let description = route.params.description;
  let isBeforeOrder = route.params.isBeforeOrder;
  let id = route.params.id;

  if (deliveryType == null)
    deliveryType = 0;

  const [centerName, setCenterName] = useState('');
  const [centerTime, setCenterTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [deliveryTime, setDeliveryTime] = useState('');
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [name, setName] = useState(route.params.name);
  const [number, setNumber] = useState(route.params.number);
  const [address, setAddress] = useState(route.params.address);
  const [payoutMethod, setPayoutMethod] = useState(1);
  const [promocode, setPromocode] = useState('');
  const [promocodeInfo, setPromocodeInfo] = useState({});
  const [discount, setDiscount] = useState(0);
  const [promocodeFreePrice, setPromocodeFreePrice] = useState(0);
  const [addressData, setAddressData] = useState({ flat: -1, floor: -1, entrance: -1 });
  const [dialogChecked, SetDialogChecked] = useState(false);
  const [isCreating, setIsCreating] = useState(false);


  const setBeforeOrder = () => {
    if (isBeforeOrder === true) {
      setDate(route.params.date);
      setTime(route.params.time);
    }
  };

  const checkPromoCode = async () => {
    const response = await API.checkPromocode(promocode);

    if (response.response === true && response.found === true) {
      setPromocodeInfo(response.data);
      setDiscount(0);
      setPromocodeFreePrice(0);

      if (response.data.type === 'discount') {
        setDiscount(response.data.value);
      }

      if (response.data.type === 'price') {
        setPromocodeFreePrice(response.data.value);
      }
    }
  };

  const createOrder = async () => {
    setIsCreating(true);

    const response = await API.createOrder({
      name,
      deliveryType,
      payoutMethod,
      number,
      description,
      beforeTime: time,
      beforeDate: date,
      center,
      promocode,
    });

    if (response.response === true) {
      navigation.navigate('SuccessOrderScreen', { id: response.vId });
    } else {
      alert(response.error);
    }

    setIsCreating(false);
  };

  const getComponentsInfo = (item) => {
    let title = '';
    if (item.components != null) {
      item.components.map((comp, index) => {
        if (item.components.length - 1 === index) {
          title += comp.name;
        } else {
          title += comp.name + ',';
        }
      });
    }

    return title;
  };

  const getCart = async () => {
    setIsLoading(true);
    const cartResponse = await API.getCart();

    if (center == null) {
      const c = await API.getCenter();

      const response = await API.getAvailableCenters();

      response.map((center) => {
        if (c === center) {
          setCenterName(center.title);
          setCenterTime(center.time);
        }
      });
    } else {
      if (center.title == null) {
        const c = await API.getCenter();

        const response = await API.getAvailableCenters();

        response.map((center) => {
          if (c === center) {
            setCenterName(center.title);
            setCenterTime(center.time);
          }
        });
      }
      setCenterName(center.title);
    }

    if (cartResponse === false) {
      alert(
        'Произошла ошибка загрузки корзины, вернитесь обратно и попробуйте оформить заказ еще раз',
      );
    }

    setCart(cartResponse);

    if (isNotHits(cartResponse).length > 0)
      SetDialogChecked(true);
    else
      SetDialogChecked(false);

    setIsLoading(false);

  };

  const getDeliveryTime = () => {
    let date = new Date(Date.now());

    date = new Date(date.getTime() + 3 * 3600 * 1000 + centerTime * 1000 * 60);

    return date.toISOString();
  };

  const getBeforeOrderTimeToInt = () => {
    if (route.params.date == null || route.params.time == null || route.params.isBeforeOrder != true) return 0;
    let data = route.params.date.split('.');
    return new Date(data[2] + '-' + data[1] + '-' + data[0] + "T" + route.params.time).getTime();
  }

  const getBeforeOrderTime = () => {
    return date + ' ' + time;
  };

  const isNotHits = (data) => {
    let hits = [];

    let time = getBeforeOrderTimeToInt();

    if (time == null || time == 0) return hits;

    if (data != null)
      data.map((item) => {
        if (item.hit_expired < time && Number(item.hit) == 1) {
          hits.push(item);
        }
      });
    else
      cart.map((item) => {
        if (item.hit_expired < time && Number(item.hit) == 1) {
          hits.push(item);
        }
      });

    return hits;
  }

  const getTotalPrice = () => {
    let price = 0;

    cart.map((item) => {
      if (isBeforeOrder == true) {
        if (item.hit_expired < getBeforeOrderTimeToInt() && item.hit == 1)
          item.finalPrice = Number(item.standard_price);
      }
      price += item.finalPrice * item.count;
    });

    if (discount > 0) {
      price = (price / 100) * (100 - discount);
    }

    price -= promocodeFreePrice;

    price /= 10;
    price = price.toFixed(0);
    price *= 10;

    if (price < 500 && deliveryType == 0)
      price += 90;

    return price;
  };

  const init = async () => {
    let data = await API.getCoords();
    if (data.flat != null && data.floor != null && data.entrance != null)
      setAddressData({ flat: data.flat, floor: data.floor, entrance: data.entrance });
  }

  useEffect(() => {
    setBeforeOrder();
    getCart();
    init();

  }, []);

  useEffect(() => {
    checkPromoCode();
  }, [promocode]);

  return (
    <ScrollView style={{ flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto' }}>
      <View style={style.header}>
        <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
      </View>
      {isLoading ? (
        <Loader />
      ) : (
        <View style={{ marginTop: '0%' }}>
          <Text style={{ fontSize: 26, fontWeight: 'bold', textAlign: 'center' }}>
            Подтверждение заказа
          </Text>


          <View style={style.header}>
            {deliveryType === 0 && (
              <View style={style.inputContainer}>
                <Text style={{ fontSize: 16, fontWeight: 'bold', alignItems: 'center' }}>
                  Адрес доставки:{' '}
                  <Text style={{ fontSize: 15 }}>{address.title} </Text>
                  {
                    addressData.flat != -1 && addressData.flat.length > 0 && (
                      <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 17 }}>
                        Квартира: {addressData.flat}
                      </Text>
                    )
                  }

                  {
                    addressData.floor != -1 && addressData.floor.length > 0 && (
                      <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 17 }}>
                        Этаж: {addressData.floor}
                      </Text>
                    )
                  }

                  {
                    addressData.entrance != -1 && addressData.entrance.length > 0 && (
                      <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 17 }}>
                        Подьезд: {addressData.entrance}
                      </Text>
                    )
                  }
                </Text>
              </View>
            )}

            {deliveryType === 1 && (
              <View style={style.inputContainer}>
                <Text style={{ fontSize: 16, fontWeight: 'bold', alignItems: 'center' }}>
                  Самовывоз с {centerName}
                </Text>
              </View>
            )}
          </View>

          <View style={{ marginTop: 10, width: '100%', alignItems: 'center', maxWidth: 500 }}>
            {description.length > 0 && (
              <View
                style={{ alignItems: 'center', width: '100%', maxWidth: 500, alignItems: 'center' }}>
                <Text style={{ fontSize: 17, fontWeight: 'bold', textAlign: 'center' }}>
                  Комментарий к заказу:
                </Text>
                <Text style={{ fontSize: 17, fontWeight: 'normal', textAlign: 'center' }}>
                  {description}
                </Text>
              </View>
            )}
          </View>

          <View style={{ marginTop: 0, width: '100%', alignItems: 'center' }}>
            {deliveryTime.length > 5 && time == null && date == null && (
              <View
                style={{ alignItems: 'center', width: '100%', maxWidth: 500 }}>
                <Text style={{ fontSize: 20, fontWeight: 'bold' }}>
                  Доставим ~ {deliveryTime}
                </Text>
              </View>
            )}
          </View>

          <View style={{ marginTop: 0, width: '100%', alignItems: 'center' }}>
            {time != null && date != null && (
              <View
                style={{ alignItems: 'center', width: '100%', maxWidth: 500 }}>
                <Text style={{ fontSize: 20, fontWeight: 'bold' }}>
                  Предзаказ на {getBeforeOrderTime()}
                </Text>
              </View>
            )}
          </View>

          <View style={{ marginTop: 10, width: '100%', alignItems: 'center' }}>
            {cart.length > 0 && (
              <View
                style={{ alignItems: 'center', width: '100%', maxWidth: 500 }}>
                <Text style={{ fontSize: 24, fontWeight: 'bold' }}>Товары:</Text>
                {cart.map((c, index) => (
                  <View key={index}>
                    <Text style={{ fontWeight: 'bold', fontSize: 18 }}>
                      {c.title} {c.count} шт. - {c.finalPrice * c.count}₽
                    </Text>
                    {c.components != null && (
                      <>
                        {c.components.length > 0 && (
                          <Text
                            numberOfLines={1}
                            style={{
                              fontSize: 14,
                              marginLeft: 10,
                              fontWeight: 'normal',
                            }}>
                            {getComponentsInfo(c)}
                          </Text>
                        )}
                      </>
                    )}
                  </View>
                ))}
              </View>
            )}

            {promocodeFreePrice != null && (
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  fontWeight: 'bold',
                  marginTop: 25,
                }}>
                Итого: {getTotalPrice()}₽
              </Text>
            )}

            <Dialog overlayStyle={{ maxWidth: 400 }} isVisible={dialogChecked} onBackdropPress={SetDialogChecked}>
              <Dialog.Title
                titleStyle={{ textAlign: 'center' }}
                title="Акционные товары"
              />
              <Text style={{ textAlign: 'center' }}>
                Данные товары потеряют свою скидку при оформлении предзаказа на {date} {time}
              </Text>
              {isNotHits().map((item, index) => <Text key={index} style={{ textAlign: "center" }}>{item.title} - {item.standard_price} руб</Text>)}
              <Button
                containerStyle={{ marginTop: '5%' }}
                onPress={() => {
                  SetDialogChecked(false);
                }}
                title="Хорошо"
              />
            </Dialog>
          </View>

          <View>
            <Text
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                marginTop: 15,
                fontSize: 20,
              }}>
              Способ оплаты:
            </Text>
            <Picker
              style={{ maxWidth: 200, alignSelf: 'center', width: '100%' }}
              selectedValue={payoutMethod}
              onValueChange={(value) => setPayoutMethod(value)}>
              <Picker.Item value="1" label="Картой при получении" />
              <Picker.Item value="2" label="Наличными" />
            </Picker>
          </View>

          <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Text
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                marginTop: 15,
                fontSize: 20,
              }}>
              Промокод
            </Text>
            <View
              style={{
                width: 150,
                height: 50,
                backgroundColor: COLORS.light,
                alignItems: 'center',
                borderRadius: 20,
              }}>
              <TextInput
                value={promocode}
                onChangeText={async (promo) => {
                  setPromocode(promo);
                }}
                maxLength={30}
                style={{ width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', height: '100%' }}
                placeholder="Промокод"
              />
            </View>
          </View>

          {promocodeInfo.type === 'discount' && (
            <Text
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: COLORS.primary,
                fontSize: 17,
              }}>
              Скидка {promocodeInfo.value}% по промокоду "{promocode}"
            </Text>
          )}

          {promocodeInfo.type === 'price' && (
            <Text
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: COLORS.primary,
                fontSize: 17,
              }}>
              Скидка {promocodeInfo.value}₽ по промокоду "{promocode}"
            </Text>
          )}

          <View
            style={{
              marginTop: 50,
              justifyContent: 'center',
              alignSelf: 'center',
              maxWidth: 200,
              width: '100%',
            }}>
            {isCreating ? (
              <Button
                buttonStyle={{
                  backgroundColor: COLORS.primary,
                  height: 60,
                  borderRadius: 30,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                loading
                title="Заказ оформляется"
              />
            ) : (
              <PrimaryButton
                onPress={() => createOrder()}
                title="Оформить заказ"
              />
            )}
          </View>
        </View>
      )}
    </ScrollView>
  );
}

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 80,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CheckoutFinish;
