import React, {Component, useEffect, useState} from 'react';
import {SafeAreaView, StyleSheet, Text, View} from 'react-native';
import COLORS from '../../../consts/colors';
import {FlatList, TextInput} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {PrimaryButton} from '../../components/Button';
import API from '../../../api/server';
import {Input} from 'react-native-elements';

let maxWidth = 800;

function CheckoutDescription({navigation, route}) {
  let name = route.params.name;
  let number = route.params.number;
  let address = route.params.address;
  let deliveryType = route.params.deliveryType;
  let date = route.params.date;
  let time = route.params.time;
  let center = route.params.center;
  let isBeforeOrder = route.params.isBeforeOrder;

  const [description, setDescription] = useState('');

  const next = () => {
    navigation.navigate('CheckoutFinish', {
      name,
      description,
      number,
      address,
      deliveryType,
      date,
      time,
      center,
      isBeforeOrder,
    });
  };

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto'}}>
      <View style={style.header}>
        <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
      </View>
      <View style={{marginTop: 30}}>
        <Text style={{fontSize: 26, fontWeight: 'bold', textAlign: 'center'}}>
          Если хотите, то вы можете указать комментарий к заказу
        </Text>
        <View style={style.header}>
          <View style={style.inputContainer}>
          <Input
              onChangeText={(text) => {
                setDescription(text);
              }}
              maxLength={60}
              style={{flex: 1, fontSize: 18, width: '100%'}}
              placeholder="Не обязательно"
            />
          </View>
        </View>

        <View
          style={{
            marginTop: 50,
            justifyContent: 'center',
            alignSelf: 'center',
            maxWidth: 200,
            width: '100%',
          }}>
          <PrimaryButton title="Продолжить" onPress={() => next()} />
        </View>
      </View>
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 80,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CheckoutDescription;
