import AsyncStorage from '@react-native-async-storage/async-storage';

export default class API {
  static async getCenter() {
    let location = await AsyncStorage.getItem('coords');
    return JSON.parse(location).center;
  }

  static async logout() {
    await AsyncStorage.removeItem('account');
  }

  static async getCoords() {
    return JSON.parse(await AsyncStorage.getItem('coords'));
  }

  static async updateCookies() {
    try {
      const response = await fetch('https://рикимару.рф/api/cookies/auth/update', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          ... await this.getAccount()
        }),
      });

      return response.json();
    } catch (err) {
      return { response: false };
    }
  }

  static async getCenterStatus() {
    try {
      const response = await fetch('https://рикимару.рф/api/status', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          center: await this.getCenter(),
        }),
      });

      return response.json();
    } catch (err) {
      return {};
    }
  }

  static async searchByQuery(query) {
    try {
      const response = await fetch('https://рикимару.рф/api/items/search', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          center: await this.getCenter(),
          ...(await this.getJsonAccountData()),
          query: query,
        }),
      });

      return response.json();
    } catch (err) {
      return [];
    }
  }

  static async getJsonAccountData() {
    try {
      let account = await this.getAccount();

      if (
        account.number != null &&
        account.auth_token != null &&
        account.number.length === 11
      ) {
        return {
          number: account.number,
          token: account.auth_token,
        };
      } else {
        return {
          register: false,
        };
      }
    } catch (err) {
      return { register: false };
    }
  }

  static async getAccount() {
    try {
      let account = await AsyncStorage.getItem('account');
      account = JSON.parse(account);

      if (account.auth_token == null) {
        account.auth_token = '';
      }

      if (account.number == null) {
        account.number = '';
      }

      return account;
    } catch (err) {
      return {};
    }
  }

  static async verificationNeed({ number, auth_token }) {
    try {
      let account = await this.getAccount();
      account.number = number;
      await AsyncStorage.setItem('account', JSON.stringify(account));

      const response = await fetch(
        'https://рикимару.рф/api/check/verification',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'credentials': 'same-origin'
          },
          body: JSON.stringify({ number: number, auth_token: auth_token }),
        },
      );

      return response.json();
    } catch (err) {
      return { response: false };
    }
  }

  static async getCategories() {
    try {
      const response = await fetch('https://рикимару.рф/api/categories', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({ center: await this.getCenter() }),
      });

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async getHits() {
    try {
      const response = await fetch('https://рикимару.рф/api/hits', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          center: await this.getCenter(),
          ...(await this.getJsonAccountData()),
        }),
      });

      return response.json();
    } catch (err) {
      return [];
    }
  }

  static async getOffers({ cost }) {
    try {
      const response = await fetch('https://рикимару.рф/api/offers', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          center: await this.getCenter(),
          cost,
          ...(await this.getJsonAccountData()),
        }),
      });

      return response.json();
    } catch (err) {
      return [];
    }
  }

  static async addCartItem(id, components, uuid) {
    try {
      const response = await fetch(`https://рикимару.рф/api/cart/add/${id}`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        mode: 'cors',
        body: JSON.stringify({
          components: components,
          uuid: uuid,
          center: await this.getCenter(),
          count: 1,
          ...(await this.getJsonAccountData()),
        }),
      });

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async removeCartItem(uuid, count) {
    try {
      const response = await fetch('https://рикимару.рф/api/cart/remove', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        mode: 'cors',
        body: JSON.stringify({
          uuid: uuid,
          count: count,
          ...(await this.getJsonAccountData()),
        }),
      });

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async getCart() {
    try {
      const response = await fetch('https://рикимару.рф/api/cart', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          center: await this.getCenter(),
          ...(await this.getJsonAccountData()),
        }),
      });

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async getItemById(id) {
    try {
      const response = await fetch(`https://рикимару.рф/api/item/${id}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          center: await this.getCenter(),
          ...(await this.getJsonAccountData()),
        }),
      });

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async getItemsMoreComponentsById(id) {
    try {
      const response = await fetch(`https://рикимару.рф/api/cart/items/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({ ...(await this.getJsonAccountData()) }),
      });

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async GeoMap(lat, lng) {
    try {
      const response = await fetch('https://рикимару.рф/api/geocoding', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({ lat: lat, lng: lng }),
      });

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async GeoMapCoords(address) {
    try {
      const response = await fetch('https://рикимару.рф/api/geocoding/coords', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({ address: address }),
      });

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async pinNearestCenter(lat, lng) {
    try {
      const response = await fetch('https://рикимару.рф/api/center/pin', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({ coords: { lat: lat, lng: lng } }),
      });

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async getItemsInCategory(id) {
    try {
      const response = await fetch('https://рикимару.рф/api/category/items', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          id: id,
          center: await this.getCenter(),
          ...(await this.getJsonAccountData()),
        }),
      });

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async sendCode({ name, number }) {
    try {
      const response = await fetch(
        'https://рикимару.рф/api/number/verification',
        {
          method: 'post',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'credentials': 'same-origin'
          },
          body: JSON.stringify({
            number,
            name,
            ...(await this.getJsonAccountData()),
          }),
        },
      );

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async confirmCode({ number, code }) {
    try {
      const response = await fetch('https://рикимару.рф/api/number/confirm', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          number: number,
          code: code,
          ...(await this.getJsonAccountData()),
        }),
      });

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async getAvailableDates() {
    try {
      const response = await fetch('https://рикимару.рф/api/order/times', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({ ...(await this.getCoords()) })
      });

      return response.json();
    } catch (err) {
      return false;
    }
  }

  static async checkAvailablePromocode(code) {
    try {
      const response = await fetch('https://рикимару.рф/api/order/promocode', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          center: await this.getCenter(),
          code: code,
          ...(await this.getJsonAccountData()),
        }),
      });

      return response.json();
    } catch (err) {
      return {};
    }
  }

  static async createOrder({
    id,
    name,
    coords,
    deliveryType,
    payoutMethod,
    number,
    description,
    beforeDate,
    beforeTime,
    code,
    center,
    promocode,
  }) {
    try {
      let _center = center;

      if (deliveryType === 0) {
        _center = await this.getCenter();
      }

      console.log({
        id: id,
        name: name,
        coords: coords,
        deliveryType: deliveryType,
        payoutMethod: payoutMethod,
        number: number,
        center: _center,
        description: description,
        beforeDate: beforeDate,
        beforeTime: beforeTime,
        code: code,
        promocode,
        ...(await this.getJsonAccountData()),
        who: await this.getCoords(),
      });
      const response = await fetch('https://рикимару.рф/api/order/create', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          id: id,
          name: name,
          coords: coords,
          deliveryType: deliveryType,
          payoutMethod: payoutMethod,
          number: number,
          center: _center,
          description: description,
          beforeDate: beforeDate,
          beforeTime: beforeTime,
          code: code,
          promocode,
          ...(await this.getJsonAccountData()),
          who: await this.getCoords(),
        }),
      });

      return response.json();
    } catch (err) {
      return {};
    }
  }

  static async getInfoOrder({ id }) {
    try {
      const response = await fetch('https://рикимару.рф/api/order/info', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          id: id,
          ...(await this.getJsonAccountData()),
        }),
      });

      return response.json();
    } catch (err) {
      return {};
    }
  }

  static async checkPromocode(promocode) {
    try {
      const response = await fetch(
        'https://рикимару.рф/api/promocode/available',
        {
          method: 'post',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'credentials': 'same-origin'
          },
          body: JSON.stringify({
            promocode,
            ...(await this.getJsonAccountData()),
          }),
        },
      );

      return response.json();
    } catch (err) {
      return { response: false };
    }
  }

  static async getLatestOrders() {
    try {
      const response = await fetch('https://рикимару.рф/api/user/orders', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          ...(await this.getJsonAccountData()),
        }),
      });

      return response.json();
    } catch (err) {
      return {};
    }
  }

  static async reOrder(id) {
    try {
      const response = await fetch('https://рикимару.рф/api/reorder', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          ...(await this.getJsonAccountData()),
          id: id,
        }),
      });

      return response.json();
    } catch (err) {
      return {};
    }
  }

  static async getAccountName() {
    try {
      const response = await fetch('https://рикимару.рф/api/account/info', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
        body: JSON.stringify({
          ...(await this.getJsonAccountData()),
        }),
      });

      return response.json();
    } catch (err) {
      return {};
    }
  }

  static async getAvailableCenters() {
    try {
      const response = await fetch('https://рикимару.рф/api/centers', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
      });

      return response.json();
    } catch (err) {
      return {};
    }
  }

  static async getJobs() {
    try {
      const response = await fetch('https://рикимару.рф/api/jobs', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
      });

      return response.json();
    } catch (err) {
      return { response: false };
    }
  }

  static async getActiveOrder() {
    try {
      const response = await fetch('https://рикимару.рф/api/orders/active', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'same-origin'
        },
      });

      return response.json();
    } catch (err) {
      return { response: false };
    }
  }
}
