import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, View, Image, Linking } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import API from '../../api/server';
import COLORS from '../../consts/colors';
import { PrimaryButton } from '../components/Button';

let maxWidth = 800;

const JobScreen = ({ navigation }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [jobs, setJobs] = useState([]);


    const getJobs = async () => {
        setIsLoading(true)
        const response = await API.getJobs();

        try {
            if (response['response'] === true) {
                setJobs(response.jobs);
            }
        } catch (err) {

        }

        setIsLoading(false);
    }

    useEffect(() => {
        getJobs();
    }, [])

    return (
        <View style={{ flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto' }}>
            <View style={{ height: 100 }}>
                <Image
                    style={{
                        width: '100%',
                        height: '100%',
                        resizeMode: 'contain',
                    }}
                    source={{ uri: 'https://static.rikimaruzd.store/logo/onboardImage.png' }}
                />
            </View>
            <View style={style.textContainer}>
                <Text style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', marginTop: '5%', fontSize: 24 }}>Вакансии RIKIMARU</Text>
                {!isLoading && (
                    <>
                        {jobs.map(job =>
                            <Text style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', marginTop: '1%', fontSize: 21, margin: '1%' }}>{job}</Text>
                        )}
                    </>
                )}

                {isLoading && (
                    <Text style={{textAlign: 'center'}}>Загружается</Text>
                )}
                <PrimaryButton
                    onPress={() => navigation.navigate('BoardScreen')}
                    title="Назад"
                />
            </View>
        </View>
    );
};

const style = StyleSheet.create({
    textContainer: {
        flex: 1,
        paddingHorizontal: 50,
        justifyContent: 'space-between',
        paddingBottom: 40,
    },
    indicatorContainer: {
        height: 50,
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
    },
    currentIndicator: {
        height: 12,
        width: 30,
        borderRadius: 10,
        backgroundColor: COLORS.primary,
        marginHorizontal: 5,
    },
    indicator: {
        height: 12,
        width: 12,
        borderRadius: 6,
        backgroundColor: COLORS.grey,
        marginHorizontal: 5,
    },
});

export default JobScreen;
