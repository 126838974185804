import React, {Component, useEffect, useState} from 'react';
import {SafeAreaView, StyleSheet, Text, View} from 'react-native';
import COLORS from '../../../consts/colors';
import {FlatList, TextInput} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {PrimaryButton} from '../../components/Button';
import API from '../../../api/server';
import Loader from '../../components/Loader';

let maxWidth = 800;

function CheckoutDescription({navigation, route}) {
  let id = route.params.id;

  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getOrder = async () => {
    setIsLoading(true);
    const response = await API.getInfoOrder({id: id});

    if (response.response === true) {
      setOrder(response);
    }
    setIsLoading(false);
  };

  const getDeliveryTime = () => {
    if (order.status == null) {
      return;
    }

    if (order.status.time == null) {
      return;
    }

    let date = new Date(Date.now());
    date = new Date(
      date.getTime() + order.status.time * 1000 * 60,
    );

    let firstDate = '';
    let hours = date.getHours();

    let minutes = date.getMinutes();

    if (hours < 10) {
      hours = '0' + hours;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    firstDate = hours + ':' + minutes;

    let secondDate = '';

    let sDate = new Date(date.getTime() + 1000 * 600);

    hours = sDate.getHours();

    minutes = sDate.getMinutes();

    if (hours < 10) {
      hours = '0' + hours;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    secondDate = hours + ':' + minutes;

    return firstDate + ' - ' + secondDate;
  };

  const getPickupTime = () => {
    if (order.status == null) {
      return;
    }

    if (order.status.time == null) {
      return;
    }

    let date = new Date(Date.now());
    date = new Date(
      date.getTime() + order.status.time * 1000 * 60,
    );

    let firstDate = '';
    let hours = date.getHours();

    let minutes = date.getMinutes();

    if (hours < 10) {
      hours = '0' + hours;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    firstDate = hours + ':' + minutes;

    return firstDate;
  };

  const getBeforeTime = () => {
    if (order.beforeOrder == null) {
      return;
    }

    let date = new Date(order.beforeOrder - 3 * 3600 * 1000);

    let firstDate = '';
    let hours = date.getHours();
    let month = date.getMonth();
    let data = date.getDate();

    month += 1;

    let minutes = date.getMinutes();

    if (hours < 10) {
      hours = '0' + hours;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    if (month < 10) {
      month = '0' + month;
    }

    if (data < 10) {
      data = '0' + data;
    }

    firstDate = data + '-' + month + ' ' + hours + ':' + minutes;

    return firstDate;
  };

  const getComponentsInfo = (item) => {
    let title = '';
    if (item.components != null) {
      item.components.map((comp, index) => {
        if (item.components.length - 1 === index) {
          title += comp.name;
        } else {
          title += comp.name + ',';
        }
      });
    }

    return title;
  };

  useEffect(() => {
    getOrder();
  }, [route.params.id]);

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto'}}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <View style={style.header}>
            <Icon
              name="arrow-back-ios"
              size={28}
              onPress={() => navigation.navigate('Home')}
            />
          </View>
          <View style={{marginTop: '30%'}}>
            <Text
              style={{fontSize: 26, fontWeight: 'bold', textAlign: 'center'}}>
              Ваш заказ оформлен
            </Text>

            <Text
              style={{fontSize: 26, fontWeight: 'bold', textAlign: 'center'}}>
                Номер заказ <Text style={{color: 'red'}}>{order.id}</Text>
            </Text>

            {order.deliveryType === 1 && order.beforeOrder == null && (
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  textAlign: 'center',
                }}>
                Заказ вы можете забрать на точке {order.centerInfo.title}
              </Text>
            )}

            {order.deliveryType === 1 && order.beforeOrder != null && (
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  textAlign: 'center',
                }}>
                Предзаказ на {getBeforeTime()}
              </Text>
            )}

            {order.deliveryType === 0 && order.beforeOrder != null && (
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  textAlign: 'center',
                }}>
                Предзаказ на {getBeforeTime()}
              </Text>
            )}

            <View style={{justifyContent: 'center', marginTop: 15}}>
              {order.deliveryType === 0 && order.beforeOrder == null && (
                <Text
                  style={{
                    fontWeight: 'bold',
                    fontSize: 20,
                    textAlign: 'center',
                  }}>
                  Доставим до {getDeliveryTime()}
                </Text>
              )}

              {order.deliveryType === 1 && order.beforeOrder == null && (
                <Text
                  style={{
                    fontWeight: 'bold',
                    fontSize: 20,
                    textAlign: 'center',
                  }}>
                  По готовности админ вам перезвонит
                </Text>
              )}
            </View>

            <View style={{marginTop: 10, width: '100%', alignItems: 'center'}}>
              <View
                style={{alignItems: 'center', width: '100%', maxWidth: 500}}>
                <Text style={{fontSize: 24, fontWeight: 'bold'}}>Товары:</Text>
                {order.content.map((c, index) => (
                  <View key={index}>
                    <Text style={{fontWeight: 'bold', fontSize: 18}}>
                      {c.title} {c.count} шт. - {c.price}₽
                    </Text>
                    {c.components != null && (
                      <>
                        {c.components.length > 0 && (
                          <Text
                            numberOfLines={1}
                            style={{
                              fontSize: 14,
                              marginLeft: 10,
                              fontWeight: 'normal',
                            }}>
                            {getComponentsInfo(c)}
                          </Text>
                        )}
                      </>
                    )}
                  </View>
                ))}
              </View>

              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  fontWeight: 'bold',
                  marginTop: 25,
                }}>
                Итого: {order.price}₽
              </Text>
            </View>

            <View>
              <Text
                style={{
                  color: 'red',
                  fontSize: 20,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}>
                Спасибо что вы с нами!
              </Text>
            </View>
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 80,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CheckoutDescription;
