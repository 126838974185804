import React, {Component, useEffect, useState} from 'react';
import {SafeAreaView, StyleSheet, Text, View} from 'react-native';
import COLORS from '../../../consts/colors';
import {FlatList, TextInput} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {PrimaryButton} from '../../components/Button';
import {MaskedTextInput} from 'react-native-mask-text';
import API from '../../../api/server';

let maxWidth = 800;

function CheckoutPhone({navigation, route}) {
  let name = route.params.name;

  const [number, setNumber] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  const set = (raw) => {
    setNumber(raw);

    if (raw.length === 11) {
      setConfirmed(true);
    } else {
      setConfirmed(false);
    }
  };

  const check = () => {
    if (number.length === 11) {
      setConfirmed(true);
      return true;
    } else {
      setConfirmed(false);
      return false;
    }
  };

  const next = async () => {
    if (!check()) {
      return;
    }

    const response = await API.sendCode({name, number});

    if (response.response !== true) {
      alert('Превышен лимит отправки звонков');
      return;
    } else {
      navigation.navigate('CheckoutPhoneConfirm', {name, number});
    }
  };

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto'}}>
      <View style={style.header}>
        <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
      </View>
      <View style={{marginTop: 30}}>
        <Text style={{fontSize: 24, fontWeight: 'bold', textAlign: 'center'}}>
          Введите свой номер телефона
        </Text>
        <View style={style.header}>
          <View style={style.inputContainer}>
            <Icon name="phone" size={28} />
            <MaskedTextInput
              value={number}
              mask="+7 (999) 999-9999"
              onChangeText={(text, rawText) => {
                set(rawText);
              }}
              placeholder="+7 ("
              style={{width: '100%'}}
            />
          </View>
        </View>

        <View
          style={{
            marginTop: 50,
            justifyContent: 'center',
            alignSelf: 'center',
            maxWidth: 200,
            width: '100%',
          }}>
          {confirmed && (
            <PrimaryButton title="Продолжить" onPress={() => next()} />
          )}
        </View>
      </View>
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 50,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CheckoutPhone;
