import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import COLORS from '../../consts/colors';
import { PrimaryButton } from './Button';

function CartOffers({ navigation, deliveryPrice, cost }) {

  return (
    <>
      {deliveryPrice === 90 && cost < 500 && (
        <>
          <View>
            <Text
              style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>
              Закажите еще на {500 - (cost)}₽ и доставка будет
              бесплатной
            </Text>
            <View
              style={{
                maxWidth: 200,
                width: '100%',
                justifyContent: 'center',
                alignSelf: 'center',
              }}>
              <PrimaryButton
                onPress={() => navigation.navigate('CartOffersScreen', { cost })}
                title="Предложения"
              />
            </View>
          </View>
        </>
      )}
    </>
  );
}

const style = StyleSheet.create({
  header: {
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 20,
  },
  cartCard: {
    height: 100,
    elevation: 15,
    borderRadius: 10,
    backgroundColor: COLORS.white,
    marginVertical: 10,
    marginHorizontal: 20,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionBtn: {
    width: 80,
    height: 30,
    backgroundColor: COLORS.primary,
    borderRadius: 30,
    paddingHorizontal: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
  },
});

export default CartOffers;
