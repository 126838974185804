class Delivery {
  constructor() {}

  static inPolygon({lat: lat, lng: lng}) {
    let inPolygon = false;
    let deliveryPrice = 80;

    this.let.map((area) => {
      if (this.pointInPolygon([lng, lat], area.coordinates)) {
        inPolygon = true;
        deliveryPrice = area.price;
      }
    });

    return {access: inPolygon, price: deliveryPrice};
  }

  static pointInPolygonFlat(point, vs, start, end) {
    let x = point[0],
      y = point[1];
    let inside = false;
    if (start === undefined) {
      start = 0;
    }
    if (end === undefined) {
      end = vs.length;
    }
    let len = (end - start) / 2;
    for (let i = 0, j = len - 1; i < len; j = i++) {
      let xi = vs[start + i * 2 + 0],
        yi = vs[start + i * 2 + 1];
      let xj = vs[start + j * 2 + 0],
        yj = vs[start + j * 2 + 1];
      let intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) {
        inside = !inside;
      }
    }
    return inside;
  }

  static pointInPolygon(point, vs, start, end) {
    if (vs.length > 0 && Array.isArray(vs[0])) {
      return this.pointInPolygonNested(point, vs, start, end);
    } else {
      return this.pointInPolygonFlat(point, vs, start, end);
    }
  }

  static pointInPolygonNested(point, vs, start, end) {
    let x = point[0],
      y = point[1];
    let inside = false;
    if (start === undefined) {
      start = 0;
    }
    if (end === undefined) {
      end = vs.length;
    }
    let len = end - start;
    for (let i = 0, j = len - 1; i < len; j = i++) {
      let xi = vs[i + start][0],
        yi = vs[i + start][1];
      let xj = vs[j + start][0],
        yj = vs[j + start][1];
      let intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) {
        inside = !inside;
      }
    }
    return inside;
  }

  static let = [
    {
      price: 90,
      coordinates: [
        [48.463592279091195, 55.836381638986744],
        [48.512963754171444, 55.83313250181144],
        [48.577460346555895, 55.83766332573553],
        [48.595206506816226, 55.860972974670034],
        [48.57924199875947, 55.885293745006685],
        [48.54786650354426, 55.8969197348029],
        [48.50331501757184, 55.862199228164194],
        [48.48262982164887, 55.857806486754505],
        [48.47985719455762, 55.854755942688],
        [48.47625230564161, 55.84307079858189],
        [48.462304818764174, 55.84338470185107],
        [48.45857118381536, 55.83993162571212],
        [48.4619745509221, 55.83904465927353],
        [48.46253897785337, 55.8383234505953],
        [48.463918796325004, 55.837867876653874],
        [48.46360158339533, 55.836866648629574],
        [48.463592279091195, 55.836381638986744],
      ],
    },
    {
      price: 150,
      coordinates: [
        [48.57894828631757, 55.88519281849507],
        [48.59452850027155, 55.86154492147046],
        [48.6187737257705, 55.871297072205735],
        [48.615254667543, 55.877232324896916],
        [48.610018995546056, 55.881333389983695],
        [48.59431197955487, 55.88302193737761],
        [48.57894828631757, 55.88519281849507],
      ],
    },
    {
      price: 150,
      coordinates: [
        [48.4425244644968, 55.84416022548793],
        [48.44097951210395, 55.84174556768431],
        [48.439434559711486, 55.84024840421218],
        [48.43574384010711, 55.836336187648065],
        [48.4369783413817, 55.83423802977707],
        [48.45376828468723, 55.834887118224074],
        [48.461096079715915, 55.834965610863925],
        [48.46331694877996, 55.836324112274966],
        [48.46356907642761, 55.83781237132922],
        [48.4621475056496, 55.838600250032],
        [48.46150109327689, 55.83910285392809],
        [48.460597188838875, 55.839412261496335],
        [48.45874646461868, 55.83995862393939],
        [48.451622517475116, 55.84478801185133],
        [48.44570019997031, 55.845922830565165],
        [48.4425244644968, 55.84416022548793],
      ]
    }
  ];
}

export default Delivery;
