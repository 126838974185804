import React, {Component, useState} from 'react';
import COLORS from '../../../consts/colors';
import {
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {PrimaryButton} from '../../components/Button';
import API from '../../../api/server';
import {MaskedTextInput} from 'react-native-mask-text';
import {ListItem} from 'react-native-elements';
import Delivery from '../../../api/delivery';
import AsyncStorage from '@react-native-async-storage/async-storage';

let maxWidth = 800;

function CheckoutChangeAddress({navigation, route}) {
  let number = route.params.number;
  let name = route.params.name;
  let deliveryType = route.params.deliveryType;

  const [confirmed, setConfirmed] = useState(false);
  const [address, setAddress] = useState({});
  const [query, setQuery] = useState('');
  const [result, setResult] = useState([]);

  const confirm = async () => {
    if (address.title != null) {
      if (address.title.length > 10) {
        navigation.navigate('CheckoutAddress', {
          number,
          name,
          address,
          deliveryType,
        });
      }
    }
  };

  const search = async (value) => {
    setQuery(value);

    if (value.length < 2) {
      return;
    }

    const response = await API.GeoMapCoords(value);

    if (response === false) {
      alert('Произошла ошибка поиска адреса');
    }

    if (response.response === true) {
      setResult(response.query);
    }
  };

  const updateAddress = async (data) => {
    let info = Delivery.inPolygon({lat: data.latitude, lng: data.longitude});

    if (info.access !== true) {
      alert('На данный адрес доставка не осуществляется');
    } else {
      await AsyncStorage.setItem(
        'coords',
        JSON.stringify({
          latitude: data.latitude,
          longitude: data.longitude,
          address: data.title,
        }),
      );
      setAddress({
        title: data.title,
        latitude: data.latitude,
        longitude: data.longitude,
      });
      setConfirmed(true);
    }
  };

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto'}}>
      <View style={style.header}>
        <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
      </View>

      <View style={{marginTop: '0%'}}>
        <Text style={{fontSize: 24, fontWeight: 'bold', textAlign: 'center'}}>
          Укажите и выберите адрес доставки
        </Text>

        <View style={style.header}>
          <View style={style.inputContainer}>
            <Icon name="place" size={28} />
            <TextInput
              value={query}
              onChangeText={(text, rawText) => {
                search(text);
              }}
              placeholder="Введите адрес"
              style={{width: '100%'}}
            />
          </View>
        </View>

        <ScrollView style={{maxHeight: 150, maxWidth: 400, width: '100%'}}>
          <View
            style={{
              alignSelf: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
            {result.map((ad, index) => (
              <TouchableOpacity onPress={() => updateAddress(ad)}>
                <ListItem key={index}>
                  <ListItem.Title>{ad.title}</ListItem.Title>
                </ListItem>
              </TouchableOpacity>
            ))}
          </View>
        </ScrollView>

        {address.title != null && (
          <>
            {address.title.length > 10 && (
              <>
                <Text
                  style={{
                    fontWeight: 'bold',
                    fontSize: 20,
                    textAlign: 'center',
                  }}>
                  Выбранный адрес:
                </Text>
                <Text
                  style={{
                    maxWidth: 400,
                    textAlign: 'center',
                    alignSelf: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    fontSize: 16,
                    fontWeight: 'bold',
                  }}>
                  <Text>{address.title}</Text>
                </Text>
              </>
            )}
          </>
        )}
        <View
          style={{
            marginTop: 50,
            justifyContent: 'center',
            alignSelf: 'center',
            maxWidth: 200,
            width: '100%',
          }}>
          {confirmed && (
            <PrimaryButton title="Применить" onPress={() => confirm()} />
          )}
        </View>
      </View>
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 50,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CheckoutChangeAddress;
