import React, { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, View, Text, Image } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import COLORS from '../../consts/colors';
import ItemInCart from '../components/ItemInCart';
import Loader from '../components/Loader';
import API from '../../api/server';

let maxWidth = 800;

const DetailsScreen = ({ navigation, route }) => {
    const [order, setOrder] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const getActiveOrder = async () => {
        setIsLoading(true);
        let data = await API.getActiveOrder();
        setIsLoading(false);

        if (data.response == true)
            if (data.order != null)
                return setOrder(data.order);
                
        return navigation.navigate('Home')
    }

    useEffect(() => {
        getActiveOrder();
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, marginHorizontal: 'auto', width: '100%' }}>
                    <View style={style.header}>
                        <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
                        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Информация о заказе {order.uid}</Text>
                    </View>
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <Text style={{ fontWeight: "bold", textAlign: "center", maxWidth: 400, fontSize: 24 }}>Отслеживание заказа</Text>
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 280,
                                marginLeft: '5%'
                            }}>
                            {order.status == "confirming" && (
                                <>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Ожидает подтверждения
                                    </Text>
                                    <Text style={{ color: "gray", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, opacity: 0.5, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="adjust" size={32} />
                                        Готовится
                                    </Text>
                                    <Text style={{ color: "gray", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, opacity: 0.5, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="adjust" size={32} />
                                        Ожидает курьера
                                    </Text>
                                    <Text style={{ color: "gray", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, opacity: 0.5, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="adjust" size={32} />
                                        У курьера
                                    </Text>
                                    <Text style={{ color: "gray", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, opacity: 0.5, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="adjust" size={32} />
                                        Заказ выполнен
                                    </Text>
                                </>
                            )}

                            {order.status == "processing" && (
                                <>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Ожидает подтверждения
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Готовится
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, opacity: 0.5, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="adjust" size={32} />
                                        Ожидает курьера
                                    </Text>
                                    <Text style={{ color: "gray", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, opacity: 0.5, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="adjust" size={32} />
                                        У курьера
                                    </Text>
                                    <Text style={{ color: "gray", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, opacity: 0.5, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="adjust" size={32} />
                                        Заказ выполнен
                                    </Text>
                                </>
                            )}

                            {order.status == "searching" && (
                                <>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Ожидает подтверждения
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Готовится
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Ожидает курьера
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, opacity: 0.5, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="adjust" size={32} />
                                        У курьера
                                    </Text>
                                    <Text style={{ color: "gray", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, opacity: 0.5, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="adjust" size={32} />
                                        Заказ выполнен
                                    </Text>
                                </>
                            )}

                            {order.status == "delivery" && (
                                <>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Ожидает подтверждения
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Готовится
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Ожидает курьера
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        У курьера
                                    </Text>
                                    <Text style={{ color: "gray", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, opacity: 0.5, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="adjust" size={32} />
                                        Заказ выполнен
                                    </Text>
                                </>
                            )}

                            {order.status == "success" && (
                                <>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Ожидает подтверждения
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Готовится
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Ожидает курьера
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        У курьера
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Заказ выполнен
                                    </Text>
                                </>
                            )}

                            {order.status == "cancelled" && (
                                <>
                                    <Text style={{ color: "red", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: "red", alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Ожидает подтверждения
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: "black", alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Готовится
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: COLORS.primary, alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Ожидает курьера
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: "black", alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        У курьера
                                    </Text>
                                    <Text style={{ color: "black", fontSize: 20, textAlign: "center", maxWidth: 450, width: "100%", alignItems: 'center', flexDirection: "row", display: "flex" }}>
                                        <Icon style={{ marginRight: 15, color: "black", alignItems: "center", marginVertical: "auto", justifyContent: "center", display: "flex" }}
                                            name="circle" size={32} />
                                        Заказ выполнен
                                    </Text>
                                </>
                            )}

                            <Text style={{ fontWeight: "bold", fontSize: 16, textAlign: "center", marginTop: '5%' }}>RIKIMARU с радостью готовит ваш заказ!</Text>
                        </View>
                    </ScrollView>
                </SafeAreaView>
            )}
        </>
    );
};

const style = StyleSheet.create({
    header: {
        paddingVertical: 20,
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 20,
    },
    details: {
        paddingHorizontal: 20,
        paddingTop: 40,
        paddingBottom: 60,
        backgroundColor: COLORS.primary,
        borderTopRightRadius: 40,
        borderTopLeftRadius: 40,
    },
    iconContainer: {
        backgroundColor: COLORS.white,
        height: 50,
        width: 50,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 30,
    },
    detailsText: {
        marginTop: 10,
        lineHeight: 22,
        fontSize: 16,
        color: COLORS.white,
    },
});

export default DetailsScreen;
