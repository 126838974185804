import React, { useEffect, useState, useRef } from 'react';
import {
  Dimensions,
  Image,
  SafeAreaView,
  SectionList,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {
  FlatList,
  ScrollView,
  TextInput,
  TouchableHighlight,
  TouchableOpacity,
} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import COLORS from '../../consts/colors';
import API from '../../api/server.js';

const { width, height } = Dimensions.get('window');

const sliderData = [
  { title: "", description: "", source: "https://friends.sandello.ru/static/slider/5.png" },
  { title: "", description: "", source: "https://friends.sandello.ru/static/slider/6.png" },
  { title: "", description: "", source: "https://friends.sandello.ru/static/slider/1.png" },
  { title: "", description: "", source: "https://friends.sandello.ru/static/slider/2.png" },
  { title: "", description: "", source: "https://friends.sandello.ru/static/slider/4.png" }

]

let maxWidth = 1280;

if (width < maxWidth)
  maxWidth = width;

let cardWidth = 160;
let cardCount = 2;

if (maxWidth > 400) {
  cardCount = (maxWidth / (cardWidth)).toFixed(0);
  cardCount -= 1;
}

import ItemInCart from '../components/ItemInCart';
import Overload from "../components/overload";
import Card from '../components/Card';
import ListCategories from '../components/ListCategories';
import UnderListCategories from '../components/UnderListCategories';
import SaleProduct from '../components/SaleProduct';


const HomeScreen = ({ navigation }) => {
  const [activeOrder, setActiveOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [foods, setFoods] = useState([]);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1);

  const [sales, setSales] = useState([]);

  const updateCategory = async (index) => {
    setIsLoading(true);
    const response = await API.getItemsInCategory(index);
    if (response !== false) {
      setFoods(response);
    }
    setIsLoading(false);
  };

  const getHits = async () => {
    setIsLoading(true);
    const response = await API.getHits();

    if (response.response !== false) {
      setSales(response.hits);
    }
    setIsLoading(false);
  }

  const getCategories = async () => {
    setIsLoading(true);
    const response = await API.getCategories();

    if (response !== false) {
      setCategories(response);
    }
    setIsLoading(false);
  };

  const getFoodPrice = (food) => {
    let price = food.finalPrice;

    if (food.count < 1) {
      return price;
    }

    if (food.components != null) {
      if (food.components.length > 0) {
        food.components.map((c) => {
          if (c.default) {
            price += Number(c.price);
          }
        });
      }
    }

    return price;
  };

  function isUnder(category) {
    let isUnder = false;
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].under === category.id) {
        isUnder = true;
        break;
      }
    }

    return isUnder;
  }

  const updateCount = ({ id, count }) => {
    let tmp = [];
    let items = foods;

    items.map((f) => {
      if (f.id === id) {
        if (count == null)
          f['count'] = 1;
        else
          f['count'] = count;
      }
      tmp.push(f);
    });

    setFoods(tmp);
  };

  const updateActiveOrder = () => {
    API.getActiveOrder().then((data) => {
      if (data.response == true)
        if (data.order != null)
          return setActiveOrder(data.order);

      setActiveOrder({});
    });
  }

  useEffect(() => {
    getCategories();
    getHits();
    updateActiveOrder();

    navigation.addListener('focus', () => {
      updateActiveOrder();
    });

    setInterval(() => {
      if (index + 1 >= sliderData.length)
        index = 0;
      else
        index++

      sliderRef.current.scrollTo({ x: width * index, animated: true })
    }, 3000)
  }, []);

  const sliderRef = useRef(null);
  let index = 0;

  return (
    <>
      <ScrollView style={{ backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto' }}>
        <View style={style.header}>
          <View style={{ flexDirection: 'row' }}>
            <Text onPress={() => setSelectedCategoryIndex(-1)} style={{ fontSize: 28, fontWeight: 'bold', marginLeft: 10, alignItems: "center", display: "flex" }}>
              Зеленодольск <Text style={{ fontSize: 16 }}>10:00 - 22:30</Text>
            </Text>
          </View>
        </View>

        <View style={{ justifyContent: 'center' }}>
          <ListCategories categories={categories} updateCategory={updateCategory} isUnder={isUnder} categoryId={selectedCategoryIndex} setCategory={setSelectedCategoryIndex} />
          <UnderListCategories updateCategory={updateCategory} categories={categories} categoryId={selectedCategoryIndex} isUnder={isUnder} />
        </View>

        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <FlatList
            showsVerticalScrollIndicator={false}
            numColumns={cardCount}
            data={foods}
            renderItem={({ item }) => (
              <Card updateCount={updateCount} navigation={navigation} getFoodPrice={getFoodPrice} cardWidth={cardWidth} food={item} />
            )}
          />

          {selectedCategoryIndex == -1 && (
            <FlatList
              showsVerticalScrollIndicator={false}
              numColumns={cardCount}
              data={sales}
              renderItem={({ item }) => <SaleProduct updateCount={updateCount} navigation={navigation} getFoodPrice={getFoodPrice} cardWidth={cardWidth} food={item} />}
            />
          )}

          <ScrollView scrollToOverflowEnabled={true} ref={(ref) => (sliderRef.current = ref)} showsHorizontalScrollIndicator={false} pagingEnabled horizontal style={{ width: Dimensions.get("window").width }}>
            {sliderData.map((item, index) => <Image resizeMode="contain" key={index} source={{ uri: item.source }} style={{ width, height: height / 2 }} />)}
          </ScrollView>

        </View>

        <Overload />

      </ScrollView>
      {activeOrder.id != null && (
        <View style={{
          position: "absolute",
          borderRadius: 15,
          zIndex: 10,
          shadowColor: "black",
          shadowRadius: 10,
          left: 0,
          right: 0,
          bottom: 15,
          width: "90%",
          maxWidth: 500,
          margin: "auto",
          height: 75,
          backgroundColor: COLORS.secondary
        }}>
          <TouchableOpacity onPress={() => navigation.navigate('activeOrder', { order: activeOrder })}>
            <Text style={{ textAlign: "center", fontSize: 20, fontWeight: "bold", alignItems: "center", marginVertical: "auto" }}>Ваш заказ готовится!</Text>
            <Text style={{ textAlign: "center", fontSize: 14, fontWeight: "bold", alignItems: "center", marginVertical: "auto" }}>Номер заказа {activeOrder.uid}</Text>
          </TouchableOpacity>
        </View>
      )}
    </>

  );
};

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 50,
    borderRadius: 20,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    backgroundColor: COLORS.primary,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    alignItems: 'center',
    marginHorizontal: 5,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  addToCartBtn: {
    height: 30,
    width: 30,
    borderRadius: 20,
    backgroundColor: COLORS.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default HomeScreen;
