import React from 'react';
import {
    FlatList,
    ScrollView,
    TextInput,
    TouchableHighlight,
    TouchableOpacity,
} from 'react-native-gesture-handler';

import {
    Image,
    StyleSheet,
    Text,
    View,
} from 'react-native';


import COLORS from '../../consts/colors.js';
import API from '../../api/server.js';
import ItemInCart from './ItemInCart.js';

export default function Card({ navigation, getFoodPrice, food, cardWidth, updateCount }) {

    const style = StyleSheet.create({
        card: {
            height: 220,
            width: cardWidth,
            marginHorizontal: 10,
            marginBottom: 20,
            marginTop: 50,
            borderRadius: 15,
            elevation: 13,
            backgroundColor: COLORS.white,
        },
        addToCartBtn: {
            height: 30,
            width: 30,
            borderRadius: 20,
            backgroundColor: COLORS.primary,
            justifyContent: 'center',
            alignItems: 'center',
        },
    });

    return (
        <TouchableHighlight
            underlayColor={COLORS.white}
            activeOpacity={0.9}
            onPress={() =>
                navigation.navigate('DetailsScreen', { food: food, updateCount })
            }>
            <View style={style.card}>
                <View style={{ alignItems: 'center', top: -40 }}>
                    <Image source={{ uri: food.src }} style={{ height: 120, width: 120 }} />
                </View>
                <View style={{ marginHorizontal: 20 }}>
                    <Text numberOfLines={1} style={{ fontSize: 18, fontWeight: 'bold' }}>
                        {food.title}
                    </Text>
                    <Text
                        numberOfLines={1}
                        style={{ maxHeight: 20, fontSize: 14, color: COLORS.grey, marginTop: 2 }}>
                        {food.description}
                    </Text>
                </View>
                <View
                    style={{
                        marginTop: 10,
                        marginHorizontal: 20,
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                    <Text style={{ fontSize: 18, fontWeight: 'bold' }}>
                        {getFoodPrice(food)}₽
                    </Text>
                    <ItemInCart navigation={navigation} full={false} food={food} />
                </View>
            </View>
        </TouchableHighlight>
    );

}
