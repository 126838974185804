import React, {Component, useEffect, useState} from 'react';
import {Image, SafeAreaView, StyleSheet, Text, View} from 'react-native';
import COLORS from '../../consts/colors';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {ScrollView} from 'react-native-gesture-handler';
import Components from '../components/Components';
import {SecondaryButton} from '../components/Button';
import API from '../../api/server';
import {Button} from 'react-native-elements'
let maxWidth = 1200;

function AddComponentsScreen({navigation, route}) {
  let updateCount = route.params.updateCount;

  let IncrementCount = route.params.IncrementCount;
  const [item, setItem] = useState(route.params.item);
  const [update, setUpdate] = useState(true);

  const [addCartLoading, setAddCartLoading] = useState(false);

  async function addComponentsItem() {
    setAddCartLoading(true);

    let requiredGroups = [];

    for (let i = 0; i < item.components.length; i++) {
      if (item.components[i].required) {
        requiredGroups.push(item.components[i].group);
      }
    }

    let success = true;

    requiredGroups.map((r) => {
      let s = false;
      item.components.map((c) => {
        if (r === c.group && c.default === true) {
          s = true;
        }
      });

      if (!s) {
        success = false;
      }
    });

    if (!success) {
      setAddCartLoading(false);
      return;
    }

    const response = await API.addCartItem(item.id, item.components);

    if (response.response) {
      navigation.goBack();
      updateCount({id: item.id, count: response.currentCount});
      IncrementCount();
    }
    setAddCartLoading(false);
  }

  const getGroups = () => {
    let groups = [];

    if (item.components != null) {
      if (item.components.length > 0) {
        item.components.map((comp) => {
          let exist = false;

          groups.map((g) => {
            if (g.id === comp.group) {
              exist = true;
            }
          });

          if (!exist) {
            groups.push({id: comp.group, title: comp.groupName});
          }
        });
      }
    }

    return groups;
  };

  const getPrice = () => {
    let price = Number(Number(item.finalPrice));

    if (item.components != null) {
      item.components.map((c) => {
        if (c.default === true) {
          price += Number(c.price);
        }
      });
    }

    return price;
  };

  const selectComponent = ({group, id}) => {
    let tmp = item.components;

    if (tmp.length > 0) {
      tmp.map((comp) => {
        if (comp.id === id && group === comp.group) {
          if (comp.required === true) {
            tmp.map((r) => {
              r.default = false;
            });
            comp.default = true;
          }
        }
      });
    }

    let final = item;
    final.components = tmp;
    setUpdate(!update);
    setItem(final);
  };

  return (
    <SafeAreaView style={{backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto'}}>
      <View style={style.header}>
        <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
        <Text style={{fontSize: 20, fontWeight: 'bold'}}>{item.title}</Text>
      </View>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: 280,
          }}>
          <Image
            source={{uri: item.src}}
            style={{borderRadius: 30, height: 220, width: 220}}
          />
        </View>
        <View style={style.details}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Text
              style={{
                fontSize: 25,
                fontWeight: 'bold',
                color: COLORS.white,
              }}>
              {item.title}
            </Text>
            <Text
              style={{
                fontSize: 22,
                fontWeight: 'bold',
                color: COLORS.white,
              }}>
              {item.finalPrice}₽
            </Text>
          </View>

          <Components
            update={update}
            groups={getGroups()}
            item={item}
            selectComponent={selectComponent}
          />

          <View style={{marginVertical: 30}}>
            {addCartLoading && <SecondaryButton title={'Добавляю...'} />}

            {!addCartLoading && (
              <Button
                buttonStyle={{backgroundColor: COLORS.white}} 
                titleStyle={{color: COLORS.primary}}
                onPress={() => addComponentsItem()}
                title={'В корзину ' + getPrice() + '₽'}
                />
            )}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  header: {
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 20,
  },
  details: {
    paddingHorizontal: 20,
    paddingTop: 40,
    paddingBottom: 60,
    backgroundColor: COLORS.primary,
    borderTopRightRadius: 40,
    borderTopLeftRadius: 40,
  },
  iconContainer: {
    backgroundColor: COLORS.white,
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
  },
  detailsText: {
    marginTop: 10,
    lineHeight: 22,
    fontSize: 16,
    color: COLORS.white,
  },
});

export default AddComponentsScreen;
