import React, { Component, useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, Text, View } from 'react-native';
import COLORS from '../../../consts/colors';
import { FlatList, TextInput } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { PrimaryButton } from '../../components/Button';
import API from '../../../api/server';
import { Button } from 'react-native-elements';

let maxWidth = 800;

function CheckoutDeliveryType({ navigation, route }) {
  let name = route.params.name;
  let number = route.params.number;
  let address = route.params.address;

  const [isLoading, setIsLoading] = useState(false);
  const [deliveryType, setDeliveryType] = useState(0);


  const next = () => {
    if (deliveryType === 0) {
      navigation.navigate('CheckoutAddress', {
        name,
        number,
        address,
        deliveryType,
      });
    }

    if (deliveryType === 1) {
      navigation.navigate('CheckoutSelectCenter', {
        name,
        number,
        address,
        deliveryType,
      });
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto' }}>
      <View style={style.header}>
        <Icon name="arrow-back-ios" size={28} onPress={() => { navigation.navigate('Корзина') }} />
      </View>
      <View style={{ marginTop: 30 }}>
        <Text style={{ fontSize: 26, fontWeight: 'bold', textAlign: 'center' }}>
          Если хотите, то вы можете указать комментарий к заказу
        </Text>
        <View style={style.header}>
          {isLoading && (
            <Text style={{ textAlign: "center", height: "100%", fontWeight: "bold", fontSize: 18 }}>Идёт загрузка</Text>
          )}

          {!isLoading && (
            <View
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}>
              <Button
                containerStyle={{ width: 250, alignSelf: 'center', padding: 10 }}
                onPress={() => setDeliveryType(0)}
                title="Доставка"
                buttonStyle={{
                  backgroundColor:
                    deliveryType === 0 ? COLORS.green : COLORS.primary,
                }}
              />

              <Button
                containerStyle={{ width: 250, alignSelf: 'center', padding: 10 }}
                onPress={() => setDeliveryType(1)}
                title="Самовывоз"
                buttonStyle={{
                  backgroundColor:
                    deliveryType === 1 ? COLORS.green : COLORS.primary,
                }}
              />
            </View>
          )}
        </View>

        <View
          style={{
            marginTop: 50,
            justifyContent: 'center',
            alignSelf: 'center',
            maxWidth: 200,
            width: '100%',
          }}>
          <PrimaryButton title="Продолжить" onPress={() => next()} />
        </View>
      </View>
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 80,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CheckoutDeliveryType;
