import React, { Component, useState } from 'react';
import {
  Dimensions,
  Image,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import COLORS from '../../consts/colors';
import { Input } from 'react-native-elements';
import {
  FlatList,
  TouchableHighlight,
} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import API from '../../api/server';
import Loader from "../components/Loader";
import ItemInCart from "../components/ItemInCart";


const { width } = Dimensions.get('window');

let maxWidth = 1280;

if (width < maxWidth)
  maxWidth = width;

let cardWidth = 160;
let cardCount = 2;

if (maxWidth > 400) {
  cardCount = (maxWidth / (cardWidth)).toFixed(0);
  cardCount -= 1;
}


function SearchScreen({ navigation }) {
  const [search, setSearch] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const SearchQuery = async (text) => {
    setIsLoading(true);
    setSearch(text);
    if (text.length < 3) {
      return;
    }
    const response = await API.searchByQuery(search);

    if (response !== false) {
      setSearchResult(response.query);
    }
    setIsLoading(false);
  };

  const updateCount = ({ id, count }) => {
    setIsLoading(true);
    let tmp = [];
    let items = searchResult;

    items.map((f) => {
      if (f.id === id) {
        f.count = count;
      }

      tmp.push(f);
    });

    setSearchResult(tmp);
    setIsLoading(false);
  };

  const Card = ({ food }) => {
    return (
      <TouchableHighlight
        underlayColor={COLORS.white}
        activeOpacity={0.9}
        onPress={() =>
          navigation.navigate('DetailsScreen', { food, updateCount })
        }>
        <View style={style.card}>
          <View style={{ alignItems: 'center', top: -40 }}>
            <Image source={{ uri: food.src }} style={{ height: 120, width: 120 }} />
          </View>
          <View style={{ marginHorizontal: 20 }}>
            <Text numberOfLines={1} style={{ fontSize: 18, fontWeight: 'bold' }}>
              {food.title}
            </Text>
            <Text
              numberOfLines={1}
              style={{ maxHeight: 20, fontSize: 14, color: COLORS.grey, marginTop: 2 }}>
              {food.description}
            </Text>
          </View>
          <View
            style={{
              marginTop: 10,
              marginHorizontal: 20,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Text style={{ fontSize: 18, fontWeight: 'bold' }}>
              {food.price}₽
            </Text>
            <ItemInCart navigation={navigation} full={false} food={food} />
          </View>
        </View>
      </TouchableHighlight>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, marginHorizontal: 'auto', width: '100%' }}>
      <View style={style.header}>
        <View style={style.inputContainer}>
          <Icon name="search" size={28} />
          <Input
            placeholder='Вкусная еда'
            value={search}
            onChangeText={(text) => {
              setSearch(text);
              SearchQuery(text);
            }}
            style={{ flex: 1, fontSize: 18, width: '100%' }}
          />

        </View>
      </View>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <View style={{ flex: 1, marginTop: 50 }}>
            <View style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', marginHorizontal: 'auto', marginBottom: '20%' }}>
              <FlatList
                showsVerticalScrollIndicator={false}
                numColumns={cardCount}
                data={searchResult}
                renderItem={({ item }) => <Card food={item} />}
              />
            </View>
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 50,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    height: 220,
    width: cardWidth,
    marginHorizontal: 10,
    marginBottom: 20,
    marginTop: 50,
    borderRadius: 15,
    elevation: 13,
    backgroundColor: COLORS.white,
  },
  addToCartBtn: {
    height: 30,
    width: 30,
    borderRadius: 20,
    backgroundColor: COLORS.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default SearchScreen;
