import React, {useEffect, useState} from 'react';
import { SafeAreaView, StyleSheet, View, Text, Image, ScrollView } from "react-native";
import {FlatList} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import API from '../../../api/server';
import COLORS from '../../../consts/colors';
import Loader from '../../components/Loader';
import { PrimaryButton } from "../../components/Button";

let maxWidth = 800;

const MyOrdersScreen = ({navigation, route}) => {
  let number = route.params.number;

  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  const retryOrder = async (id) => {
    const response = await API.reOrder(id);

    if (response.response) {
      navigation.navigate('Корзина');
    }
  };

  const getOrders = async () => {
    setIsLoading(true);
    const response = await API.getLatestOrders();

    if (response !== false) {
      setOrders(response.data);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <ScrollView style={{backgroundColor: COLORS.white, flex: 1, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto'}}>
      <View style={style.header}>
        <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
        <Text style={{fontSize: 20, fontWeight: 'bold'}}>Мои заказы</Text>
      </View>
      {isLoading ? (
        <Loader />
      ) : (
        <View>
          {orders.map((order, index) => (
            <View
              key={index}
              style={{
                backgroundColor: COLORS.light,
                width: '100%',
                marginVertical: 20,
              }}>
              <Text
                style={{
                  marginTop: 10,
                  fontWeight: 'bold',
                  fontSize: 18,
                  textAlign: 'center',
                }}>
                Заказ <Text style={{color: COLORS.primary}}>{order.uid}</Text>
              </Text>
              {order.content.map((item, i) => (
                <>
                  <Text style={{marginLeft: '5%', fontSize: 15}}>
                    {item.title} x {item.count} - {item.finalPrice * item.count}₽
                  </Text>
                </>
              ))}

            
                <Text style={{marginLeft: '5%', fontSize: 15}}>
                 Статус: {order.status}
                </Text>
  
              <Text
                style={{
                  marginLeft: '5%',
                  marginTop: 5,
                  fontSize: 18,
                  fontWeight: 'bold',
                }}>
                Итого: {order.price}₽
              </Text>

              <View style={{width: '50%', alignSelf: 'center', margin: 10}}>
                <PrimaryButton onPress={async () => await retryOrder(order.id)} title="Повторить заказ" />
              </View>
            </View>
          ))}
        </View>
      )}
    </ScrollView>
  );
};
const style = StyleSheet.create({
  header: {
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 20,
  },
  cartCard: {
    height: 100,
    elevation: 15,
    borderRadius: 10,
    backgroundColor: COLORS.white,
    marginVertical: 10,
    marginHorizontal: 20,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionBtn: {
    width: 80,
    height: 30,
    backgroundColor: COLORS.primary,
    borderRadius: 30,
    paddingHorizontal: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
  },
});

export default MyOrdersScreen;
