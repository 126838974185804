const COLORS = {
  white: '#FFF',
  dark: '#000',
  primary: '#ea4c62',
  secondary: '#fedac5',
  light: '#E5E5E5',
  grey: '#908e8c',
  green: 'green',
  blue: '#2089dc'
};

export default COLORS;
