import React, {Component, useEffect, useState} from 'react';
import {
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import {Button} from 'react-native-elements';
import COLORS from '../../consts/colors';
import {SecondaryButton} from './Button';
import API from '../../api/server';

function ItemInCart({navigation, full, food, updateCount}) {
  const [adding, setAdding] = useState(false);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  let item = food;

  useEffect(() => {
    setCount(food.count);
  }, [food]);

  const addCart = async () => {
    if (item.components.length > 0) {
      navigation.navigate('AddComponentsScreen', {
        updateCount,
        item: food,
        IncrementCount,
      });
    } else {
      const response = await API.addCartItem(item.id);

      if (response.response) {
        Count(response.currentCount);
        updateCount({id: item.id, count: response.currentCount});
      }
    }
  };

  const Count = (c) => {
    if(c == null)
      setCount(1);
    else
      setCount(c);
  };

  const IncrementCount = () => {
    setCount(count + 1);
  };

  const DecrItem = async (uuid) => {
    if (uuid == null) {
      return;
    }
    setIsLoading(true);

    const response = await API.removeCartItem(uuid, 1);

    if (response.response) {
      updateCount({id: item.id, count: response.currentCount});
      setCount(response.currentCount);
    }

    setIsLoading(false);
  };

  const decrCart = async () => {
    setIsLoading(true);

    if (item.id.components == null || item.id.components.length < 1) {
      let response = await API.getItemsMoreComponentsById(item.id);

      if (response.length === 1) {
        await DecrItem(response[0].uuid);
      }
    }
  };

  return (
    <SafeAreaView style={{maxWidth: 200, width: '100%', alignSelf: 'center'}}>
      {full ? (
        <>
          {count === 0 && (
            <View style={{marginTop: 40, marginBottom: 40, maxWidth: 200, width: '100%'}}>
              <Button
                buttonStyle={{backgroundColor: COLORS.white}} 
                titleStyle={{color: COLORS.primary}}
                onPress={() => addCart()}
                title="Добавить в корзину" />
            </View>
          )}

          {count > 0 && (
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 30,
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'center',
                }}>
                <Text
                  style={{
                    textAlign: 'center',
                    color: '#fff',
                    fontSize: 16,
                    fontWeight: 'bold',
                  }}>
                  {count * item.weight} гр. - {count * item.finalPrice}₽
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}>
                <FontAwesome
                  onPress={() => decrCart()}
                  style={{paddingRight: 30, color: 'white'}}
                  name="minus"
                  size={24}
                />
                <Text style={{fontWeight: 'bold', fontSize: 20, color: '#fff'}}>
                  {count}
                </Text>
                <FontAwesome
                  onPress={() => addCart()}
                  style={{paddingLeft: 30, color: 'white'}}
                  name="plus"
                  size={24}
                />
              </View>
            </View>
          )}
        </>
      ) : (
        <>
          {count === 0 && (
            <View style={style.addToCartBtn}>
              {!adding && <Icon name="add" size={20} color={COLORS.white} />}
            </View>
          )}

          {count > 0 && (
            <View style={style.addToCartBtn}>
              <Text style={{fontWeight: 'bold', color: '#fff'}}>{count}</Text>
            </View>
          )}
        </>
      )}
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  addToCartBtn: {
    height: 30,
    width: 30,
    borderRadius: 20,
    backgroundColor: COLORS.primary,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
});

export default ItemInCart;
