import React, {Component, useEffect, useState} from 'react';
import {SafeAreaView, StyleSheet, Text, View} from 'react-native';
import COLORS from '../../../consts/colors';
import {FlatList, TextInput} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {PrimaryButton} from '../../components/Button';
import API from '../../../api/server';
import {BottomSheet, ListItem, Overlay} from 'react-native-elements';
import {Picker} from '@react-native-picker/picker';
import Loader from '../../components/Loader';

let maxWidth = 800;

function CheckoutBeforeOrder({navigation, route}) {
  let name = route.params.name;
  let number = route.params.number;
  let address = route.params.address;
  let deliveryType = route.params.deliveryType;
  let center = route.params.center;

  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [dateIndex, setDateIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getAvailableTimes = async () => {
    setIsLoading(true);
    const response = await API.getAvailableDates();

    if (response !== false) {
      setDate(response[0].date);
      setTime(response[0].times[0]);
      setDates(response);
    }
    setIsLoading(false);
  };

  const next = () => {
    navigation.navigate('CheckoutDescription', {
      name,
      number,
      address,
      deliveryType,
      date,
      time,
      center,
    });
  };

  const updateDate = (value) => {
    setDate(value);

    dates.map((date, index) => {
      if (date.date === value) {
        setDateIndex(index);
        setTime(date.times[0]);
      }
    });
  };

  const Confirm = () => {
    setIsVisible(false);
    navigation.navigate('CheckoutDescription', {
      name,
      number,
      address,
      deliveryType,
      date,
      time,
      center,
    });
  };

  const ConfirmBeforeOrder = () => {
    console.log(date)
    console.log(time)
    if (date == null || time == null) {
      return;
    }

    setIsVisible(false);
    navigation.navigate('CheckoutDescription', {
      name,
      number,
      address,
      deliveryType,
      date,
      time,
      center,
      isBeforeOrder: true,
    });
  };

  useEffect(() => {
    getAvailableTimes();
  }, []);

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto'}}>
      <View style={style.header}>
        <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
      </View>
      {isLoading ? (
        <Loader />
      ) : (
        <View style={{marginTop: '10%'}}>
          <Text
            style={{
              fontSize: 26,
              marginHorizontal: 30,
              fontWeight: 'bold',
              textAlign: 'center',
            }}>
            Когда вы хотите получить заказ?
          </Text>

          <View
            style={{
              marginTop: 50,
              justifyContent: 'center',
              alignSelf: 'center',
              maxWidth: 300,
              width: '100%',
            }}>
            <View style={{padding: 10}}>
              <PrimaryButton
                onPress={() => setIsVisible(!isVisible)}
                title="Предварительный заказ"
              />
            </View>
            <View style={{padding: 10}}>
              <PrimaryButton
                onPress={() => Confirm()}
                title="Заказать сейчас"
              />
            </View>
          </View>

          <Overlay
            overlayStyle={{maxWidth: 350, width: '100%'}}
            isVisible={isVisible}
            onBackdropPress={() => setIsVisible(!isVisible)}>
            <View style={{maxWidth: 200, alignSelf: 'center', width: '100%'}}>
              <PrimaryButton
                title="Закрыть"
                onPress={() => setIsVisible(false)}
              />
            </View>

            <Text style={{fontWeight: 'bold', fontSize: 24, marginTop: 10}}>
              Выберите дату
            </Text>
            <Picker
              selectedValue={date}
              onValueChange={(value) => {
                updateDate(value);
              }}>
              {dates.map((date, index) => (
                <Picker.Item key={index} label={date.date} value={date.date} />
              ))}
            </Picker>

            {dates[dateIndex].times != null && (
              <>
                {dates[dateIndex].times.length > 0 && (
                  <Text style={{fontWeight: 'bold', fontSize: 24}}>
                    Выберите время
                  </Text>
                )}
              </>
            )}

            {dates[dateIndex].times != null && (
              <>
                {dates[dateIndex].times.length < 1 && (
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: 18,
                      textAlign: 'center',
                      padding: 10,
                    }}>
                    Нет доступного времени
                  </Text>
                )}
              </>
            )}

            {dates[dateIndex].times != null && (
              <>
                {dates[dateIndex].times.length > 0 && (
                  <Picker
                    selectedValue={time}
                    onValueChange={(value) => {
                      setTime(value);
                    }}>
                    {dates[dateIndex].times.map((time, index) => (
                      <Picker.Item key={index} label={time} value={time} />
                    ))}
                  </Picker>
                )}
              </>
            )}

            <View style={{maxWidth: 300, marginTop: 30, alignSelf: 'center', width: '100%'}}>
              <PrimaryButton
                onPress={() => ConfirmBeforeOrder()}
                title="Подтвердить"
              />
            </View>
          </Overlay>
        </View>
      )}
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 80,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CheckoutBeforeOrder;
