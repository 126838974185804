import React, {Component, useEffect, useState} from 'react';
import {SafeAreaView, StyleSheet, Text, View} from 'react-native';
import COLORS from '../../../consts/colors';
import {FlatList, TextInput} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {PrimaryButton} from '../../components/Button';
import API from '../../../api/server';
import Loader from '../../components/Loader';
import {Picker} from '@react-native-picker/picker';

let maxWidth = 800;

function CheckoutSelectCenter({navigation, route}) {
  let name = route.params.name;
  let number = route.params.number;
  let address = route.params.address;
  let deliveryType = route.params.deliveryType;

  const [center, setCenter] = useState(-1);
  const [centers, setCenters] = useState([]);
  const [currrentCenter, setCurrentCenter] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);

  const next = () => {
    if (currrentCenter === -1) {
      return;
    }

    navigation.navigate('CheckoutBeforeOrder', {
      name,
      number,
      address,
      deliveryType,
      center: {id: currrentCenter, title: getTitleByCenterId(currrentCenter)},
    });
  };

  useEffect(() => {
    loadCenters();
  }, []);

  const getTitleByCenterId = (id) => {
    let title = '';

    id = Number(id);

    centers.map((center) => {
      if (center.id === id) {
        title = center.title;
      }
    });

    return title;
  };

  const loadCenters = async () => {
    setIsLoading(true);
    const response = await API.getAvailableCenters();
    let account = await API.getCoords();

    let setted = false;

    if (account.center != null) {
      response.map((center) => {
        if (center.id === account.center) {
          setCurrentCenter(center.id);
          setted = true;
        }
      });
    }

    if (!setted && response.length > 0) {
      setCurrentCenter(response[0].id);
    }

    setCenters(response);
    setIsLoading(false);
  };

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: COLORS.white, maxWidth: maxWidth, width: '100%', marginHorizontal: 'auto'}}>
      <View style={style.header}>
        <Icon name="arrow-back-ios" size={28} onPress={navigation.goBack} />
      </View>
      <View style={{marginTop: 30}}>
        <Text style={{fontSize: 26, fontWeight: 'bold', textAlign: 'center'}}>
          Выберите точку с которой заберёте заказ
        </Text>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <View style={style.header}>
              <View style={style.inputContainer}>
                <Picker
                  accessibilityValue={true}
                  selectedValue={currrentCenter}
                  style={{width: '100%', height: '100%'}}
                  onValueChange={(value) => {
                    setCurrentCenter(value);
                  }}>
                  {centers.map((center) => (
                    <Picker.Item label={center.title} value={center.id} />
                  ))}
                </Picker>
              </View>
            </View>

            {center.id !== -1 && (
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  maxWidth: 200,
                  textAlign: 'center',
                  alignSelf: 'center',
                }}>
                Выбранная точка: {getTitleByCenterId(currrentCenter)}
              </Text>
            )}

            <View
              style={{
                marginTop: 50,
                justifyContent: 'center',
                alignSelf: 'center',
                maxWidth: 200,
                width: '100%',
              }}>
              <PrimaryButton title="Продолжить" onPress={() => next()} />
            </View>
          </>
        )}
      </View>
    </SafeAreaView>
  );
}

const style = StyleSheet.create({
  header: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  inputContainer: {
    flex: 1,
    height: 80,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: COLORS.light,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  sortBtn: {
    width: 50,
    height: 50,
    marginLeft: 10,
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoriesListContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  UnderCategoriesListContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  categoryBtn: {
    height: 45,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  underCategoryBtn: {
    height: 85,
    width: 120,
    marginRight: 7,
    borderRadius: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  categoryBtnImgCon: {
    height: 35,
    width: 35,
    backgroundColor: COLORS.white,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CheckoutSelectCenter;
